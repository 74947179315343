import { Image, ImageProperties } from '@components/image/image'

import { ItemType, NewsConfigType, NewsDataType, NewsPostType } from './newsTypes'

export const newsItemsTemplate = (data: NewsDataType, config: NewsConfigType) => {
  const renderPostTag = (tagValue: string) => {
    if (!tagValue || config.listStyle === 'testimonial') return ''

    return `<div class="cmp-newslist__tag">${tagValue}</div>`
  }

  const renderPostMeta = (metaValue: string, classSuffix = 'tag') => {
    if (!metaValue || config.listStyle === 'testimonial') return ''
    return `<span class="cmp-newslist__meta-tag cmp-newslist__meta-tag--${classSuffix}">${metaValue}</span>`
  }

  const renderImageTemplate = (image: ImageProperties) => {
    if (!image) return ''

    return `<div class="cmp-newslist__image">
          ${image ? Image(image) : ''}
        </div>`
  }

  let template = ''

  const renderPost = (post: NewsPostType) => `
      <li class="cmp-newslist__news-item"
        data-title="${post.title}">
      <a class="cmp-newslist__news-item-link" href="${post.url}" target="${post.webItemInsideIntranetContext ? '_blank' : '_self'}"
      rel="${post.webItemInsideIntranetContext ? 'noreferrer noopener' : ''}" data-dm="news.link">
          ${config.listStyle !== 'testimonial' ? renderPostTag(post.tagValue) : ''}
          ${config.listStyle === 'testimonial' || config.listStyle === 'teaser' ? renderImageTemplate(post.image) : ''}

          <div class="cmp-newslist__content-wrapper">
            ${
              config.listStyle !== 'testimonial'
                ? `
            <div class="cmp-newslist__meta">
                ${config.listStyle !== 'card' ? renderPostMeta(post.tagValue, '') : ''}
                ${renderPostMeta(post.date.substr(0, post.date.indexOf(' ')), 'date')}
                ${
                  config.listStyle !== 'card'
                    ? renderPostMeta(post.timeToRead && `${post.timeToRead} ${config.translations?.readingTime}`, 'time-to-read')
                    : ''
                }
            </div> `
                : ''
            }
            <h3 class="cmp-newslist__title">${post.title}</h3>
            ${config.listStyle !== 'list' ? `<p class="cmp-newslist__link-description">${post.description}</p>` : ''}
            <div class="cmp-newslist__link">
              ${post.webItemInsideIntranetContext ? '<span class="cmp-newslist__link-icon icon-link icon--md" aria-hidden="true"></span>' : ''}
              <span class="cmp-newslist__link-text" aria-label="${config.translations?.readMoreAriaLabel} ${post.title}">${
    config.translations?.readMoreLabel
  }</span>
              ${
                !post.webItemInsideIntranetContext
                  ? '<span class="cmp-newslist__link-icon icon-keyboard_arrow_right icon--md" aria-hidden="true"></span>'
                  : ''
              }
            </div>
          </div>
        </a>
    </li>`

  data.items.forEach((item: ItemType) => {
    template += renderPost(item)
  })
  return template
}

export const listTemplate = (data: NewsDataType, config: NewsConfigType) => {
  return `
    <div class="cmp-newslist-v2 cmp-newslist--${config.listStyle}">
        <ul class="cmp-newslist__news-list cmp-newslist__news-list--${config.teaserColumns}-columns" ref="list">
            ${newsItemsTemplate(data, config)}
        </ul>
    </div>
    `
}

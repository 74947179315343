/* eslint-disable max-lines */
import {
  CalendarIconPropsType,
  DateAndTimeValuesType,
  EventsConfigType,
  EventsDataItemType,
  RenderSectionDividerPropsType,
} from '@components/filterable-list/list/templates/events/eventsTypes'
import { isMobile } from '@site/js/utils/breakpoint'
import { replaceLabelPlaceholders } from '@site/js/utils/utils'

import { eventState, updateState } from '../events-state'

/**
 * calendar icon single svg
 * @param {string} month
 * @param {string} date
 * @param {string} year
 */
const calendarIconSingle = (month: string, date: string, year: string): string => `
 <svg class="c-filterable-list__events-icon c-filterable-list__events-icon--single" aria-hidden="true" width="4.375rem" height="4.375rem" viewBox="0 0 70 70" xml:space="preserve">
   <title>Pictograms/calendarcard_singleday</title>
   <defs>
     <rect id="path-1" x="0" y="0" width="64" height="64" rx="7"></rect>
     <filter x="-3.9%" y="-3.9%" width="110.9%" height="110.9%" filterUnits="objectBoundingBox" id="filter-2">
       <feOffset dx="1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
       <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
       <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
     </filter>
   </defs>
   <g id="Pictograms/calendarcard_singleday" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
     <g id="calendarcard">
       <g id="shadow" fill="black" fill-opacity="1">
         <use filter="url(#filter-2)" xlink:href="#path-1"></use>
       </g>
       <path d="M0,17.7777778 L64,17.7777778 L64,59 C64,61.7614237 61.7614237,64 59,64 L5,64 C2.23857625,64 3.38176876e-16,61.7614237 0,59 L0,17.7777778 L0,17.7777778 Z" id="bg_grey" fill="#F4F4F4"></path>
       <path d="M5,1 L59,1 C61.7614237,1 64,3.23857625 64,6 L64,18.7777778 L64,18.7777778 L0,18.7777778 L0,6 C-1.2263553e-15,3.23857625 2.23857625,1 5,1 Z" id="bg_red" fill="#EB0023"></path>
       <text class="events-icon__text events-icon__text--month" x="32" y="14" text-anchor="middle">${month}</text>
       <text class="events-icon__text events-icon__text--date" x="32" y="46" text-anchor="middle">${date}</text>
       <text class="events-icon__text events-icon__text--year" x="32" y="60" text-anchor="middle">${year}</text>
     </g>
   </g>
 </svg>
 `

/**
 * calendar icon on demand svg
 */
const calendarIconOnDemand = (): string => `
 <svg class="c-filterable-list__events-icon c-filterable-list__events-icon--single" aria-hidden="true" width="4.375rem" height="4.375rem" viewBox="0 0 70 70" xml:space="preserve">
   <title>Pictograms/calendarcard_ondemand</title>
   <defs>
     <rect id="path-1" x="0" y="0" width="64" height="64" rx="7"></rect>
     <filter x="-3.9%" y="-3.9%" width="110.9%" height="110.9%" filterUnits="objectBoundingBox" id="filter-2">
       <feOffset dx="1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
       <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
       <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
     </filter>
   </defs>
   <g id="Pictograms/calendarcard_singleday" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
     <g id="calendarcard">
       <g id="shadow" fill="black" fill-opacity="1">
         <use filter="url(#filter-2)" xlink:href="#path-1"></use>
       </g>
       <path d="M0,17.7777778 L64,17.7777778 L64,59 C64,61.7614237 61.7614237,64 59,64 L5,64 C2.23857625,64 3.38176876e-16,61.7614237 0,59 L0,17.7777778 L0,17.7777778 Z" id="bg_grey" fill="#F4F4F4"></path>
       <path d="M5,1 L59,1 C61.7614237,1 64,3.23857625 64,6 L64,18.7777778 L64,18.7777778 L0,18.7777778 L0,6 C-1.2263553e-15,3.23857625 2.23857625,1 5,1 Z" id="bg_red" fill="#EB0023"></path>
       <text class="events-icon__text events-icon__text--on-demand" x="32" y="37" text-anchor="middle">on</text>
       <text class="events-icon__text events-icon__text--on-demand" x="32" y="49" text-anchor="middle">demand</text>
     </g>
   </g>
 </svg>
 `

/**
 * calendar icon multiple svg
 * @param {string} month
 * @param {string} date
 * @param {string} year
 */
const calendarIconMultiple = (month: string, date: string, year: string): string => `
 <svg class="c-filterable-list__events-icon c-filterable-list__events-icon--multiple" aria-hidden="true" width="5rem" height="5rem" viewBox="0 0 80 80" xml:space="preserve">
   <title>Pictograms/calendarcard_multipledays</title>
   <defs>
     <rect id="path-1" x="0" y="0" width="64" height="64" rx="7"></rect>
     <filter x="-3.9%" y="-3.9%" width="110.9%" height="110.9%" filterUnits="objectBoundingBox" id="filter-2">
       <feOffset dx="1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
       <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
       <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
     </filter>
     <rect id="path-3" x="0" y="0" width="64" height="64" rx="7"></rect>
     <filter x="-3.9%" y="-3.9%" width="110.9%" height="110.9%" filterUnits="objectBoundingBox" id="filter-4">
       <feOffset dx="1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
       <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
       <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
     </filter>
     <rect id="path-5" x="0" y="0" width="64" height="64" rx="7"></rect>
     <filter x="-3.9%" y="-3.9%" width="110.9%" height="110.9%" filterUnits="objectBoundingBox" id="filter-6">
       <feOffset dx="1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
       <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
       <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
     </filter>
   </defs>
   <g id="Pictograms/calendarcard_multipledays" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
     <g id="calendarcard" transform="translate(12.000000, 0.000000)">
       <g id="shadow" fill="black" fill-opacity="1">
         <use filter="url(#filter-2)" xlink:href="#path-1"></use>
       </g>
       <path d="M0,17.7777778 L64,17.7777778 L64,59 C64,61.7614237 61.7614237,64 59,64 L5,64 C2.23857625,64 3.38176876e-16,61.7614237 0,59 L0,17.7777778 L0,17.7777778 Z" id="bg_grey" fill="#F4F4F4"></path>
       <path d="M5,0 L59,0 C61.7614237,3.50983023e-15 64,2.23857625 64,5 L64,17.7777778 L64,17.7777778 L0,17.7777778 L0,5 C-1.2263553e-15,2.23857625 2.23857625,-3.80913106e-16 5,0 Z" id="bg_red" fill="#EB0023"></path>
     </g>
     <g id="calendarcard" transform="translate(6.000000, 6.000000)">
       <g id="shadow" fill="black" fill-opacity="1">
         <use filter="url(#filter-4)" xlink:href="#path-3"></use>
       </g>
       <path d="M0,17.7777778 L64,17.7777778 L64,59 C64,61.7614237 61.7614237,64 59,64 L5,64 C2.23857625,64 3.38176876e-16,61.7614237 0,59 L0,17.7777778 L0,17.7777778 Z" id="bg_grey" fill="#F4F4F4"></path>
       <path d="M5,0 L59,0 C61.7614237,3.50983023e-15 64,2.23857625 64,5 L64,17.7777778 L64,17.7777778 L0,17.7777778 L0,5 C-1.2263553e-15,2.23857625 2.23857625,-3.80913106e-16 5,0 Z" id="bg_red" fill="#EB0023"></path>
     </g>
     <g id="calendardcard" transform="translate(0.000000, 12.000000)">
       <g id="shadow" fill="black" fill-opacity="1">
         <use filter="url(#filter-6)" xlink:href="#path-5"></use>
       </g>
       <path d="M0,17.7777778 L64,17.7777778 L64,59 C64,61.7614237 61.7614237,64 59,64 L5,64 C2.23857625,64 3.38176876e-16,61.7614237 0,59 L0,17.7777778 L0,17.7777778 Z" id="bg_grey" fill="#F4F4F4"></path>
       <path d="M5,0 L59,0 C61.7614237,3.50983023e-15 64,2.23857625 64,5 L64,17.7777778 L64,17.7777778 L0,17.7777778 L0,5 C-1.2263553e-15,2.23857625 2.23857625,-3.80913106e-16 5,0 Z" id="bg_red" fill="#EB0023"></path>
       <text class="events-icon__text events-icon__text--month" x="32" y="14" text-anchor="middle">${month}</text>
       <text class="events-icon__text events-icon__text--date" x="32" y="46" text-anchor="middle">${date}</text>
       <text class="events-icon__text events-icon__text--year" x="32" y="60" text-anchor="middle">${year}</text>
     </g>
   </g>
 </svg>
 `

/**
 * calendar icon template
 * @param {string} startDate
 * @param {string} endDate
 * @param isOnDemand
 * @param isUnscheduledEvent
 * @param unscheduledEventsUpcomingLabel
 */

export const calendarIcon = ({
  startDate,
  endDate,
  isOnDemand = false,
  isUnscheduledEvent = false,
  unscheduledEventsUpcomingLabel = '',
}: CalendarIconPropsType): string => {
  const [date, month, year] = !isUnscheduledEvent ? startDate.split(' ')[0].match(/\d+/g) : ''
  const dateObj = new Date(Date.UTC(Number(year), Number(month) - 1, Number(date)))
  const isMultiDay = endDate && endDate !== '' && endDate.split(' ')[0] !== startDate.split(' ')[0]
  const formatMonth = !isUnscheduledEvent ? Intl.DateTimeFormat('en', { month: 'long' }).format(dateObj) : ''
  const currentYear = new Date().getFullYear()

  if (isUnscheduledEvent) {
    return calendarIconSingle(unscheduledEventsUpcomingLabel, '', currentYear.toString())
  }
  if (isOnDemand) {
    return calendarIconOnDemand()
  }
  if (isMultiDay && !isMobile) {
    return calendarIconMultiple(formatMonth, date, year)
  }

  return calendarIconSingle(formatMonth, date, year)
}

/**
 * dateline template
 * @param {string} startDate
 * @param endDate
 * @param isOnDemand
 */
export const dateLine = (startDate: string, endDate = '', isOnDemand = false): string => {
  if (isOnDemand) {
    return ''
  }
  if (endDate && startDate.split(' ')[0] !== endDate.split(' ')[0]) {
    return `${startDate.split(' ')[0]} - ${endDate.split(' ')[0]}`
  }
  return startDate.split(' ')[0]
}

/**
 * location template
 * @param {string} location
 * @param isOnDemand
 * @param requestString
 */
export const locationLine = (location: string, isOnDemand = false, requestString = 'Request'): string => (isOnDemand ? requestString : location)
const renderOnDemandDivider = (onDemandLabel: string, config: EventsConfigType): string => {
  updateState({ isOnDemandSection: true })

  return `<li class="c-filterable-list__events-section">
    ${config.firstSection && config.showPastEvents ? '<a href="#">{config.translations.seePastEvents}</a>' : ''}
    <h2 class="c-filterable-list__section-title c-filterable-list__section-title--is-centered">${onDemandLabel}</h2>
   </li>`
}

const renderUnscheduledEventDivider = (unscheduledEventsLabel: string, config: EventsConfigType): string => {
  updateState({ isUnscheduledEventSection: true })
  return `<li class="c-filterable-list__events-section">
    ${config.firstSection && config.showPastEvents ? '<a href="#">{config.translations.seePastEvents}</a>' : ''}
    ${config.type === 'filterableList' ? `<div class="c-filterable-list__section-links">${renderViewLinks(config)}</div>` : ''}
    <h2 id="${new Date().getTime()}" class="c-filterable-list__section-title c-filterable-list__section-title--is-centered cmp-title__text">${unscheduledEventsLabel}</h2>
   </li>`
}

const renderTopEventDivider = (topEventLabel: string): string => {
  updateState({ isUnscheduledEventSection: true })
  return `<li class="c-filterable-list__events-section">
    <h2 class="c-filterable-list__section-title c-filterable-list__section-title--is-centered cmp-title__text">${topEventLabel}</h2>
   </li>`
}

const renderViewLinks = (config: EventsConfigType): string => {
  if (config.displayPastEventsLink === 'true') {
    return eventsLink(config)
  } else if (eventState.shouldRenderViewModeLinks && !eventState.isPastOrUpcomingEventLinkRendered) {
    updateState({ isPastOrUpcomingEventLinkRendered: true })
    if (eventState.viewMode !== 'pastEvents') {
      return pastEventsLink(config)
    } else {
      return `<a href="javascript:void(0)" ref="linkUpcomingEventsMode" class="c-filterable-list__link c-filterable-list__link-view-mode u-float--right js-filterable-list-show-upcoming-events">${config.translations.seeUpcomingEvents}</a>`
    }
  }
  return ''
}
export const pastEventsLink = (config: EventsConfigType): string => {
  const pastEvents = document.querySelector('.js-filterable-list-show-past-events')

  const markup = `<a href="javascript:void(0)" ref="linkPastEventsMode" class="c-filterable-list__link c-filterable-list__link-view-mode u-float--left js-filterable-list-show-past-events">${config.translations.seePastEvents}</a>`

  if (!pastEvents) return markup
  return ''
}
// To be analyzed why this is renredered for every heading in filterable list component
export const eventsLink = (config: EventsConfigType): string => {
  const pastEvent = config.pasteventsLink ? JSON.parse(config.pasteventsLink) : null
  const upcomingEvent = config.upcomingeventsLink ? JSON.parse(config.upcomingeventsLink) : null

  const link = pastEvent && pastEvent.link !== '' ? pastEvent.link : upcomingEvent.link
  const linkText = pastEvent && pastEvent.linkText !== '' ? pastEvent.linkText : upcomingEvent.linkText

  const pastEventsElement = document.querySelector('.js-filterable-list-show-past-events')
  const upcomingEventsElement = document.querySelector('.js-filterable-list-show-upcoming-events')
  const linkClasses =
    pastEvent.link !== '' && pastEvent.linkText !== ''
      ? 'js-filterable-list-show-past-events u-float--left'
      : 'js-filterable-list-show-upcoming-events u-float--right'

  const markup = `<a href='${link}'
        class="c-filterable-list__link c-filterable-list__link-view-mode ${linkClasses}">${linkText}</a>`

  if (!pastEventsElement || !upcomingEventsElement) return markup
  return ''
}

export const getDateAndTimeValues = (dateString: string): DateAndTimeValuesType => {
  if (!dateString) {
    return null
  }

  const splitDate = dateString.split(' ')
  const [date = 0, month = 0, year = 0] = splitDate?.[0]?.match(/\d+/g) || []
  const [hours = 0, minutes = 0, seconds = 0] = splitDate?.[1]?.match(/\d+/g) || []
  const dateObj = new Date(Date.UTC(Number(year), Number(month) - 1, Number(date), Number(hours), Number(minutes), Number(seconds)))
  const formatMonth = Intl.DateTimeFormat('en', { month: 'long' }).format(dateObj)

  return {
    date: Number(date),
    month: Number(month),
    year: Number(year),
    hours: Number(hours),
    minutes: Number(minutes),
    seconds: Number(seconds) || 0,
    formatMonth,
    dateObj,
    dateString,
  }
}

export const renderDateDivider = (date: string, config: EventsConfigType): string => {
  const { month, year, formatMonth } = getDateAndTimeValues(date)
  updateState({ [`lastStartDate_${config.randomListId}`]: month })
  return `<li class="c-filterable-list__events-section">
  <div class="c-filterable-list__section-links">${renderViewLinks(config)}</div>
  <h2 id="${formatMonth}-${year}-${new Date().getTime()}" class="c-filterable-list__section-title c-filterable-list__section-title--is-centered cmp-title__text" data-anchor-label="${formatMonth} ${year}">${formatMonth} ${year}</h2>
</li>`
}

/**
 * Render section divider
 * @param {*} date
 * @param {*} isOnDemand
 * @param config
 * @param isUnscheduledEvent
 * @param isTopEvent
 */

export const renderSectionDivider = ({ date, isOnDemand, config, isUnscheduledEvent, isTopEvent = false }: RenderSectionDividerPropsType): string => {
  const onDemandLabel = config.translations.onDemandLabel
  const unscheduledEventsLabel = config.translations.unscheduledEventsLabel
  const topEventLabel = config.translations.topEvent

  if (config.simple === 'true' || config.carousel === 'true') return ''

  const month = !isUnscheduledEvent ? getDateAndTimeValues(date)?.month : ''
  if (eventState.isOnDemandSection && !isOnDemand) updateState({ isOnDemandSection: false })
  if (eventState.isUnscheduledEventSection && !isUnscheduledEvent) updateState({ isUnscheduledEventSection: false })
  if (eventState.isTopEventSection && !isTopEvent) updateState({ isTopEventSection: false })

  if (isTopEvent !== eventState.isTopEventSection) {
    return renderTopEventDivider(topEventLabel)
  }
  if (isUnscheduledEvent !== eventState.isUnscheduledEventSection) {
    return renderUnscheduledEventDivider(unscheduledEventsLabel, config)
  }
  if (isOnDemand !== eventState.isOnDemandSection) {
    return renderOnDemandDivider(onDemandLabel, config)
  }
  if (!eventState.isOnDemandSection && !eventState.isUnscheduledEventSection && month !== eventState[`lastStartDate_${config.randomListId}`]) {
    return renderDateDivider(date, config)
  }
  return ''
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const checkDelay = (item: EventsDataItemType, config: EventsConfigType, isTopEvent: boolean): string => {
  const [dayStart = '', monthStart = '', yearStart = ''] = !item.unscheduledEvent ? item.startDate.split(' ')[0].match(/\d+/g) : ''
  const [dayEnd = '', monthEnd = '', yearEnd = ''] = !item.unscheduledEvent ? item.endDate.split(' ')[0].match(/\d+/g) : ''
  const [hourStart, minuteStart] = !item.unscheduledEvent ? item.startDate.split(' ')[1].match(/\d+/g) : ''
  const [hourEnd, minuteEnd] = !item.unscheduledEvent ? item.endDate.split(' ')[1].match(/\d+/g) : ''
  const dateStart = new Date(Date.UTC(+yearStart, +monthStart - 1, +dayStart)).getTime()
  const dateEnd = new Date(Date.UTC(+yearEnd, +monthEnd - 1, +dayEnd, +hourEnd, +minuteEnd)).getTime()
  const now = new Date()
  now.setHours(0, 0, 0, 0)
  const daysStart = Math.floor((dateStart - now.getTime()) / (1000 * 60 * 60 * 24))
  const daysEnd = Math.floor((dateEnd - now.getTime()) / (1000 * 60 * 60 * 24))
  const minutesStart = Math.floor(
    (new Date(Date.UTC(Number(yearStart), Number(monthStart) - 1, Number(dayStart), Number(hourStart), Number(minuteStart))).getTime() -
      new Date().getTime()) /
      (1000 * 60 * 60),
  )
  const minutesEnd = Math.floor((dateEnd - new Date().getTime()) / (1000 * 60 * 60))
  if (isTopEvent) {
    return `<div class="c-filterable-list__events-badge"><p>${
      config.displayTopEventAsTeaser === 'true' ? item.eventType : config.translations.topEvent
    }</p></div>`
  }
  let markup = ''
  if (daysStart <= 3 && daysEnd >= 0) {
    if (daysStart > 1) {
      markup = `<div class="c-filterable-list__events-badge"><p>${replaceLabelPlaceholders(config.translations.countdownFuture, daysStart)}</p></div>`
    } else if (daysStart === 1) {
      markup = `<div class="c-filterable-list__events-badge"><p>${config.translations.countdownTomorrow}</p></div>`
    } else if (daysStart <= 0 && daysEnd >= 0) {
      if (minutesStart > 0) {
        markup = `<div class="c-filterable-list__events-badge"><p>${config.translations.countdownToday}</p></div>`
      } else if (minutesStart <= 0 && minutesEnd > 0 && (item.isOngoing == null || item.isOngoing) && eventState.viewMode !== 'pastEvents')
        markup = `<div class="c-filterable-list__events-badge"><p>${config.translations.ongoingEvent}</p></div>`
    }
  }
  return markup
}

import './videos.scss'

import { imageTemplate } from '@components/image/image'
import { formatDate } from '@site/js/utils/formatDate'

import { ItemDataType, VideosConfigType, VideosDataType } from './videosTypes'

const isVideoTeaserList = (config: VideosConfigType) => config.videoteaserlist === 'true'

const videoPublicationDateContainer = (item: ItemDataType, config: VideosConfigType) =>
  `<div class="c-filterable-list__video-publication">
      ${
        item.viewCounter > 0
          ? `<p class="c-filterable-list__video-views">
        <span>${item.viewCounter}</span>
        <span>${item.viewCounter === 1 ? config.translations.viewLabel : config.translations.viewsLabel} |</span>
      </p>`
          : ''
      }
      <p>
        <span>${formatDate(item.publicationDate, config.translations.temporal)}</span>
        </p>
  </div>`

const videoTeaserListHeadingTitle = (title: string) => `
<div class="cmp-linklist__heading">
    <h3 class="cmp-linklist__title">
     <span class="icon-play_circle_outline"></span>
     <span class="cmp-linklist_title--text">${title}</span>
    </h3>
  </div>
`

const gotoPowerTvBtn = (powertvPageLink: string) => `
  <a data-dm="button.component" class="button cmp-button--secondary-positive" href="${powertvPageLink}">
    <div class="cmp-button">
      <span class="cmp-button__text">Go to Energy TV</span>
      <span class="cmp-button__icon cmp-button__icon--keyboard_arrow_right"></span>
    </div>
  </a>
`

export const itemsTemplate = (data: VideosDataType, config: VideosConfigType) =>
  `${data.items
    .map(item => {
      // Video List template
      return `
    <li class="c-filterable-list__video-teaser cmp-teaser" data-item-link="${item.url}">
      <a href="${item.url}" class="c-filterable-list__video-link" aria-label="${config.translations?.readMoreLabel}: ${item.title}"></a>
      <div class="cmp-teaser__image">
        ${item.image ? imageTemplate(item.image) : ''}
      </div>
      <div class="${!isVideoTeaserList(config) ? 'c-filterable-list__video-content' : ''} cmp-teaser__content">
      ${videoPublicationDateContainer(item, config)}
      ${
        isVideoTeaserList(config)
          ? `<div class="c-filterable-list__video-title-link cmp-teaser__title u-color--primary">
            <span class="c-filterable-list__video-title-link--icon icon-play_circle_outline"></span>
            <span class="c-filterable-list__video-title-link--title">${item.title}</span>
         </div>`
          : `<div class="c-filterable-list__video-title cmp-teaser__title">
            <span class="cmp-teaser__title-text">${item.title}</span>
         </div>`
      }
      </div>
    </li>
`
    })
    .join('')}`

export const template = (data: VideosDataType, config: VideosConfigType) => `
  <div class="c-filterable-list__videos">
    ${isVideoTeaserList(config) ? videoTeaserListHeadingTitle(config.videotitle) : ''}
    <p class="c-filterable-list__results"></p>
    <ul class="c-filterable-list__teaser-wrapper
        ${isVideoTeaserList(config) ? 'c-filterable-list__teaser-wrapper--column-3' : 'c-filterable-list__teaser-wrapper--column-4'}" ref="list">
      ${itemsTemplate(data, config)}
    </ul>
    ${isVideoTeaserList(config) ? gotoPowerTvBtn(config.powertvPageLink) : ''}
  </div>
  `

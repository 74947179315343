import './carousel/events-carousel.scss'

import { EventsConfigType, EventsDataType } from '@components/filterable-list/list/templates/events/eventsTypes'
import { teaserItemsTemplate, teaserTemplate } from '@components/filterable-list/list/templates/events/teaser/teaser'

import { carouselItemsTemplate, carouselTemplate } from './carousel/events-carousel'
import { eventState, updateState } from './events-state'
import { listItemsTemplate, listTemplate } from './list/events-list'

/**
 * list template selector
 * @param {object} data
 * @param {object} config
 */
export const itemsTemplate = (data: EventsDataType, config: EventsConfigType) => {
  if (config.carousel === 'true') return carouselItemsTemplate(data, config)
  if (config.listDisplayStyle === 'teaser') return teaserItemsTemplate(data, config)
  return listItemsTemplate(data, config)
}

/**
 * list template selector
 * @param {object} data
 * @param {object} config
 */
export const template = (data: EventsDataType, config: EventsConfigType) => {
  if (!eventState.pastItemsFlagInitialized) {
    updateState({ shouldRenderViewModeLinks: data.pastItemsExist, pastItemsFlagInitialized: true })
  }
  if (config.carousel === 'true') return carouselTemplate(data, config)
  if (config.listDisplayStyle === 'teaser') return teaserTemplate(data, config)
  return listTemplate(data, config)
}

import './Sortbar.scss'

import SortbarDropdown from './SortbarDropdown'
import { SortbarPropsType } from './SortbarTypes'
import { SortOrder } from './SortOrder'

export function Sortbar({ sortable, displaySortOrder, selectedFiltersProp, translations, sortByOptions, onSortSelect }: SortbarPropsType) {
  return (
    <>
      {(sortable || displaySortOrder) && (
        <div className="cmp-sortbar">
          <SortOrder displaySortOrder={displaySortOrder} translations={translations} />
          <SortbarDropdown
            sortable={sortable}
            displaySortOrder={displaySortOrder}
            selectedFilters={selectedFiltersProp}
            translations={translations}
            sortByOptions={sortByOptions}
            onSortSelect={onSortSelect}
          />
        </div>
      )}
    </>
  )
}

/* eslint-disable max-lines */
import { FilterableList } from '@components/filterable-list/filterable-list'
import { SelectedFilter } from '@react/Filterbar/Filters/FiltersTypes'
import { useListWrapperProps } from '@react/ListWrapper/ListWrapperTypes'
import { getRequest } from '@site/js/utils/request'
import { useRef, useState } from 'react'

import { LAST_ACTIVE_FILTER } from '../constants'
import {
  arrayFromSearchQueryParams,
  getSearchQueryParams,
  getUrl,
  mergeAndUpdate,
  multipleSelectionHandler,
  setFiltersFromQueryParams,
  uniqueArrayElements,
} from '../helpers'

export const useListWrapper = (props: useListWrapperProps) => {
  const filterableList = useRef(null)
  const [notificationsChannel, setNotificationsChannel] = useState(null)
  const [translationsState, setTranslationsState] = useState(null)
  const [analyticsState, setAnalyticsState] = useState(null)
  const [selectedFilters, setSelectedFilters] = useState([])
  const [urlParams, setUrlParams] = useState(window.location.search)
  const [isFilterbarLoaded, setIsFilterbarLoaded] = useState(false)
  const [filterFieldsState, setFilterFieldsState] = useState(null)
  const [secondaryFilterFieldsState, setSecondaryFilterFieldsState] = useState(null)
  const [totalNumber, setTotalNumber] = useState(null)
  const [offsetState, setOffsetState] = useState(['0'])
  const [calculatedOffsetState, setCalculatedOffsetState] = useState(['0'])
  const [filtersForFilterableListState, setFiltersForFilterableListState] = useState(null)
  const [dataLoadMore, setDataLoadMore] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [filterTitle, setFilterTitle] = useState('')
  const [icon, setIcon] = useState('')
  const [filterTypeState, setFilterTypeState] = useState(null)
  const [showLoadingSpinnerOnInit, setShowLoadingSpinnerOnInit] = useState(true)

  const prefix = `filterable${props?.listId || 0}-`

  const loadFilterableList = async () => {
    setIsLoading(true)
    try {
      const currentSearchQueryParams = multipleSelectionHandler(prefix).join('&')
      const url = getUrl({ currentSearchQueryParams, prefix, offset: offsetState, calculatedOffset: calculatedOffsetState, urlFromProps: props.url })
      const replaceString = new RegExp(prefix, 'gi')
      const response = await getRequest(url.replace(replaceString, ''))
      const {
        filterFields,
        secondaryFilterFields,
        totalNumber,
        calculatedOffset,
        items,
        title,
        listIcon,
        loadMore,
        dynamicFields,
        filterType,
        jobListingUrl,
      } = await response.json()

      if (!dataLoadMore) {
        setFilterFieldsState(mergeAndUpdate({ currentData: filterFieldsState, newData: filterFields, filterType }))
        setSecondaryFilterFieldsState(
          mergeAndUpdate({
            currentData: secondaryFilterFieldsState,
            newData: secondaryFilterFields,
            filterType,
          }),
        )
      }

      setTotalNumber(totalNumber)
      let offsetForFilterableList = offsetState[0]
      if (loadMore) {
        setCalculatedOffsetState(calculatedOffset ? [`${calculatedOffset}`] : ['0'])
        setOffsetState(
          calculatedOffset
            ? ['0']
            : prevState => {
                offsetForFilterableList = `${Number(prevState[0]) + items.length}`
                return [offsetForFilterableList]
              },
        )
      }
      setFilterTitle(title)
      setIcon(listIcon)
      setFilterTypeState(filterType)

      const data = {
        items,
        filterFields,
        offset: Number(offsetForFilterableList),
        calculatedOffset: calculatedOffset || 0,
        loadMore,
        filters: filtersForFilterableListState,
        totalNumber,
        dynamicFields,
        jobListingUrl,
      }

      if (dataLoadMore) {
        window.dispatchEvent(
          new CustomEvent('loadmore-template-filterable-list', {
            detail: {
              data,
              id: props.listId,
            },
          }),
        )
      } else {
        FilterableList(filterableList.current, data)
      }
    } catch (error) {
      console.error(error)
    }

    if (showLoadingSpinnerOnInit) {
      setShowLoadingSpinnerOnInit(false)
    }

    setIsLoading(false)
    sessionStorage.setItem(LAST_ACTIVE_FILTER, '')
  }

  const setFilterableListConfig = () => {
    Object.entries(props)?.forEach(([key, value]) => {
      if (value) {
        const camelToDashed = key.replace(/[A-Z]/g, upperCaseCharacter => `-${upperCaseCharacter.toLowerCase()}`)
        filterableList.current.setAttribute(`data-${camelToDashed}`, value)
      }
    })
    filterableList.current.setAttribute('data-selected-filters', JSON.stringify(filtersForFilterableListState))
  }

  const urlParamsHandler = () => {
    const queryParams = arrayFromSearchQueryParams()
    const selectedFilters = []
    const filtersForFilterableList = {}
    if (queryParams.length) {
      setFiltersFromQueryParams({ queryParams, selectedFilters, filtersForFilterableList, prefix, setOffsetState, setCalculatedOffsetState })

      const isOffsetPresent = selectedFilters.some(filter => filter.key === 'offset')
      const isCalculatedOffsetPresent = selectedFilters.some(filter => filter.key === 'calculatedOffset')

      if (!isOffsetPresent) {
        selectedFilters.push({ key: 'offset', value: [offsetState] })
      }
      if (!isCalculatedOffsetPresent) {
        selectedFilters.push({ key: 'calculatedOffset', value: [calculatedOffsetState] })
      }
    } else {
      selectedFilters.push({ key: 'offset', value: ['0'] })
      selectedFilters.push({ key: 'calculatedOffset', value: ['0'] })
    }

    setFiltersForFilterableListState(filtersForFilterableList)
    setIsFilterbarLoaded(false)
    setSelectedFilters(selectedFilters)
  }

  const setOffsets = ({
    filter,
    loadMoreTemplate,
    filtersForFilterableList,
  }: {
    filter: SelectedFilter
    loadMoreTemplate: boolean
    filtersForFilterableList: Record<string, unknown>
  }) => {
    if (!loadMoreTemplate && (filter.key === 'offset' || filter.key === 'calculatedOffset')) {
      filter.value = ['0']
    }
    if (filter.key === 'offset') {
      setOffsetState(filter.value)
    }
    if (filter.key === 'calculatedOffset') {
      setCalculatedOffsetState(filter.value)
    }
    filtersForFilterableList[filter.key] = filter.value
  }

  const onSelectedFiltersChange = (selectedFilters: SelectedFilter[], loadMoreTemplate = false) => {
    const searchQueryParams = []
    const filtersForFilterableList = {}

    selectedFilters?.forEach((filter: SelectedFilter) => {
      const key = encodeURIComponent(`${prefix}${filter.key}`)
      const value = encodeURIComponent(Array.isArray(filter.value) ? filter.value.join(',') : filter.value)

      setOffsets({ filter, loadMoreTemplate, filtersForFilterableList })

      searchQueryParams.push(`${key}=${value}`)
    })

    setFiltersForFilterableListState(filtersForFilterableList)

    if (isFilterbarLoaded || loadMoreTemplate) {
      const uniqueSearchQueryParams = uniqueArrayElements(searchQueryParams)
      const otherQueryParams = getSearchQueryParams(prefix, false)
      const hasQueryParams = uniqueSearchQueryParams.length || otherQueryParams.length
      const searchQuery = hasQueryParams ? `?${[...uniqueSearchQueryParams, ...otherQueryParams].join('&')}` : ''
      setUrlParams(searchQuery)
    }
    setIsFilterbarLoaded(true)
    setDataLoadMore(false)
    setSelectedFilters(selectedFilters)
  }

  return {
    analyticsState,
    filterableList,
    filterFieldsState,
    filtersForFilterableListState,
    filterTypeState,
    icon,
    isLoading,
    notificationsChannel,
    secondaryFilterFieldsState,
    selectedFilters,
    filterTitle,
    prefix,
    showLoadingSpinnerOnInit,
    totalNumber,
    translationsState,
    urlParams,
    loadFilterableList,
    onSelectedFiltersChange,
    setAnalyticsState,
    setDataLoadMore,
    setFilterableListConfig,
    setNotificationsChannel,
    setTranslationsState,
    urlParamsHandler,
  }
}

import { request } from '../../site/js/utils/request/index'

/**
 * responsible for retriving data
 * @param {object} configuration
 */
export const Service = ({ url, defaultParams = { offset: [0] }, parser = data => data }) => {
  /**
   * handles resolved request
   * @param {object} data
   */
  const resolved = data => parser(data)

  /**
   * handles rejected request
   */
  const rejected = error => console.error(error)

  /**
   * builds url parameter string, allowing duplicate param names for filters with multiple values
   * @param {object} params
   */
  const getParamsAsString = params => {
    const urlParams = new URLSearchParams()
    for (const [key, value] of Object.entries(params)) {
      if (Array.isArray(value)) {
        value.forEach(filterValue => urlParams.append(key, filterValue))
      } else {
        urlParams.append(key, value)
      }
    }
    return urlParams.toString()
  }

  /**
   * builds url string
   * @param {object} params
   */
  const buildUrl = params => url + '?' + getParamsAsString({ ...defaultParams, ...params })

  /**
   * build url with selected params and send request
   */
  const get = (params, options = {}) => request(buildUrl(params), options).fork(rejected, resolved)

  return {
    get,
  }
}

import './ListWrapper.scss'

import { Filterbar } from '@react/Filterbar'
import { ListWrapperProps } from '@react/ListWrapper/ListWrapperTypes'
import LoadingSpinner from '@react/LoadingSpinner'
import { Sortbar } from '@react/Sortbar'
import TeamsNotifications from '@react/TeamsNotifications/TeamsNotifications'
import { cls } from '@react/utils/classname'
import { useEffect } from 'react'

import { FilterTitle } from './FilterTitle'
import { useListWrapper } from './hooks'

export function ListWrapper(props: ListWrapperProps) {
  const {
    analyticsState,
    filterableList,
    filterFieldsState,
    filtersForFilterableListState,
    filterTypeState,
    icon,
    isLoading,
    notificationsChannel,
    secondaryFilterFieldsState,
    selectedFilters,
    prefix,
    showLoadingSpinnerOnInit,
    totalNumber,
    translationsState,
    urlParams,
    loadFilterableList,
    onSelectedFiltersChange,
    setAnalyticsState,
    setDataLoadMore,
    setFilterableListConfig,
    setNotificationsChannel,
    setTranslationsState,
    urlParamsHandler,
  } = useListWrapper(props)

  const { channels = null, title = null, popupTitle = null, hintText = null, hintUrl = null } = notificationsChannel || {}
  const sortable = props?.sortable === 'true'
  const displaySortOrder = props?.displaysortorder === 'true'
  const filterTitle = props.title
  const filterbarVisible = props.filterBarType !== 'none' && filterFieldsState?.length > 0
  const titleVisible = (props.filterBarType !== 'none' && filterTitle) || filterFieldsState?.length > 0
  const componentNameProp = props?.componentName
  const dmProp = props?.dm

  useEffect(() => {
    try {
      setTranslationsState(JSON.parse(props?.translations))
    } catch (err) {
      setTranslationsState(null)
    }
    try {
      setAnalyticsState(JSON.parse(props?.analytics))
    } catch (err) {
      setAnalyticsState(null)
    }
    urlParamsHandler()

    const loadMoreEventHandler = (e: CustomEvent) => {
      const { selectedFilters, id } = e.detail
      if (id === props.listId) {
        const selectedFiltersArray = []
        for (const prop in selectedFilters) {
          selectedFiltersArray.push({ key: prop, value: selectedFilters[prop] })
        }
        setDataLoadMore(true)
        onSelectedFiltersChange(selectedFiltersArray, true)
      }
    }

    window.addEventListener('loadmore-filterable-list', loadMoreEventHandler)

    return () => window.removeEventListener('loadmore-filterable-list', loadMoreEventHandler)
  }, [])

  useEffect(() => {
    try {
      setNotificationsChannel(JSON.parse(props.notificationschannel))
    } catch (err) {
      setNotificationsChannel(null)
    }
  }, [props.notificationschannel])

  useEffect(() => {
    const parameters = new URLSearchParams(window.location.search)
    if (parameters) {
      const selectedFiltersParams = new URLSearchParams(urlParams)
      selectedFiltersParams.delete(`${prefix}offset`)
      selectedFiltersParams.delete(`${prefix}calculatedOffset`)
      const updatedParams = selectedFiltersParams.size ? `?${selectedFiltersParams.toString()}` : ''
      const relativePath = `${window.location.pathname}${updatedParams || ''}${window.location.hash}`
      history.pushState(null, '', relativePath)
    }
    loadFilterableList()
  }, [urlParams])

  useEffect(() => {
    setFilterableListConfig()
  }, [filtersForFilterableListState])

  return (
    <>
      {showLoadingSpinnerOnInit && <LoadingSpinner additionalClasses="cmp-filterbar__initial-loading-spinner" />}
      {titleVisible && <FilterTitle title={filterTitle} listIcon={icon} />}
      {filterbarVisible && (
        <div
          data-dm="filterbar.component"
          className={cls({ 'cmp-filterbar__container': true, 'cmp-filterbar__quickfilter': filterTypeState === 'quickfilter' })}
        >
          <Filterbar
            filterFields={filterFieldsState}
            secondaryFilterFields={secondaryFilterFieldsState}
            selectedFiltersProp={selectedFilters}
            title={filterTitle}
            totalNumber={totalNumber}
            translations={translationsState}
            onSelectedFiltersChange={onSelectedFiltersChange}
            isLoading={isLoading}
            analytics={analyticsState}
            componentName={componentNameProp}
            dataDm={dmProp}
          />
          <Sortbar
            sortable={sortable}
            displaySortOrder={displaySortOrder}
            selectedFiltersProp={selectedFilters}
            translations={translationsState}
            onSortSelect={onSelectedFiltersChange}
          />
          <div className="cmp-filterbar__separator"></div>
        </div>
      )}
      <div className="filterable-list-component" ref={filterableList} />
      {notificationsChannel && (
        <div
          className={cls({
            'list-wrapper__teams-notifications': true,
            'list-wrapper__teams-notifications--has-notifications': true,
          })}
        >
          <TeamsNotifications channels={channels} title={title} popupTitle={popupTitle} hintText={hintText} hintUrl={hintUrl} type="list" />
        </div>
      )}
    </>
  )
}

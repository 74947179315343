import { EventsConfigType, EventsDataItemType, EventsDataType } from '@components/filterable-list/list/templates/events/eventsTypes'
import { Image } from '@components/image/image'
import { tagListTemplate } from '@components/tagList/tagList'

export const teaserItemsTemplate = (data: EventsDataType, config: EventsConfigType): string => {
  const teaserItemTemplate = item => `<li class="c-filterable-teaser">
    <a class="c-filterable-teaser__link" href=${item.url}>
      <div class="c-filterable-teaser__image cmp-teaser__image">${Image(item.image)}</div>
      <div class="c-filterable-teaser__tag-list">
          ${tagListTemplate(item.tags)}
      </div>
      <div class="c-filterable-teaser__content">
        <div>
          <h3 class="c-filterable-teaser__content-title">${item.title}</h3>
          <p class="c-filterable-teaser__content-description">${item.description}</p>
        </div>
        <button class="c-filterable-teaser__content-button">
          <span class="c-filterable-teaser__content-button-text">${config.translations.watchNowLabel}</span>
          <span class="cmp-button__icon cmp-button__icon--keyboard_arrow_right"></span>
        </button>
      </div>
    </a>
  </li>`

  return data.items.map((item: EventsDataItemType) => teaserItemTemplate(item)).join('')
}

export const teaserTemplate = (data: EventsDataType, config: EventsConfigType): string => {
  return `
   <div class="c-filterable-list__teasers">
      <ul class="c-filterable-list__teasers-list" ref="list">
        ${teaserItemsTemplate(data, config)}
      </ul>
   </div>
  `
}

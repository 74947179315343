import { useOutsideClick } from '@hooks/useClickOutside'
import { cls } from '@react/utils/classname'
import { pxToRem } from '@site/js/utils/px-to-rem'
import { useEffect, useRef } from 'react'

type AbbreviationTemplateProps = {
  abbreviationClass: string
  abbrString: string
  isHovered: boolean
  title: string
  value: string
  position?: DOMRect
  isTooltip?: boolean
  isArray?: boolean
  hideTooltip: () => void
  showTooltip: (element: HTMLElement) => void
}

export default function AbbreviationTemplate({
  abbreviationClass,
  abbrString,
  isHovered,
  title,
  value,
  position,
  isTooltip,
  isArray,
  hideTooltip,
  showTooltip,
}: AbbreviationTemplateProps) {
  const tooltipRef = useRef<HTMLElement>(null)

  const titlesArray = isArray ? title.split(',') : []
  const valuesArray = isArray ? value.split(',') : []

  const abbreviationRef = isTooltip ? null : useOutsideClick(hideTooltip)

  const handleShowTooltip = () => {
    if (!isTooltip) {
      showTooltip(tooltipRef?.current)
    }
  }

  const handleHideTooltip = () => {
    if (!isTooltip) {
      hideTooltip()
    }
  }

  useEffect(() => {
    if (isTooltip && isHovered) {
      const { top, left, height, width } = position
      tooltipRef.current.style.top =
        abbreviationClass === 'cmp-abbreviation-tooltip--is-above-center' ? pxToRem(top - height * 2) : pxToRem(top + height / 2)
      tooltipRef.current.style.left = pxToRem(left + width / 2)
    }
  }, [position])

  return (
    <>
      <abbr
        className="cmp-abbreviation"
        ref={abbreviationRef}
        aria-label={title}
        onClick={handleShowTooltip}
        onMouseOver={handleShowTooltip}
        onMouseOut={handleHideTooltip}
      >
        {valuesArray.length > 1
          ? valuesArray.map((item, index) => (
              <span className="cmp-abbreviation-tooltip__value" key={`${index}-${value}`}>
                {item}
              </span>
            ))
          : `${value}${abbrString}`}
        {abbrString && isArray && <span className="cmp-abbreviation-tooltip__value">{abbrString}</span>}
      </abbr>
      <span
        ref={tooltipRef}
        className={cls({
          'cmp-abbreviation-tooltip--is-visible': isHovered,
          [abbreviationClass]: true,
          'cmp-tooltip--fixed': isTooltip,
        })}
      >
        {titlesArray.length > 1 ? (
          titlesArray.map((item, index) => (
            <span key={`${index}-${title}`} className="cmp-abbreviation-tooltip__text">
              {item}
            </span>
          ))
        ) : (
          <span className="cmp-abbreviation-tooltip__text">{title}</span>
        )}
      </span>
    </>
  )
}

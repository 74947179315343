import { EventsStateType } from '@components/filterable-list/list/templates/events/eventsTypes'

export let eventState: EventsStateType = {
  isOnDemandSection: false,
  isUnscheduledEventSection: false,
  isTopEventSection: false,
  viewMode: 'upcomingEvents',
  shouldRenderViewModeLinks: false,
  isPastOrUpcomingEventLinkRendered: false,
  pastItemsFlagInitialized: false,
}

export const updateState = (newState: EventsStateType) => {
  eventState = Object.assign({}, eventState, newState)
}

import { CustomIndividualDates, EventsDataItemType, IndividualDateType } from '../eventsTypes'
import { getDateAndTimeValues } from '../ui/events-ui'

export const getCustomDateMilliseconds = (date: {
  year: number
  month: number
  date: number
  hours: number
  minutes: number
  seconds: number
}): number => new Date(date.year, date.month - 1, date.date, date.hours, date.minutes, date.seconds).getTime()

// format the date to match the event date format received from backend
// for example: 21.05.2024 12:00:00
export const formatEventDate = (date = new Date()): string =>
  new Intl.DateTimeFormat('en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  })
    .format(date)
    .replace(',', '')
    .replace(/\//g, '.')

const getMilliSecondsAndDate = ({
  inRangeCurrentDate,
  nextApplicableDate,
  currentDate,
  startDateMilliseconds,
  startDate,
  individualDates,
}: {
  inRangeCurrentDate: CustomIndividualDates[]
  nextApplicableDate: CustomIndividualDates[]
  currentDateMiliseconds: number
  currentDate: Date
  startDateMilliseconds: number
  startDate: string
  individualDates: IndividualDateType[]
}): { milliseconds: number; currentStartItemDate: string; currentEndItemDate: string; isOngoing: boolean } => {
  let milliseconds!: number
  let currentStartItemDate!: string
  let currentEndItemDate!: string
  let isOngoing: boolean = false

  const isInRangeCurrentDate = inRangeCurrentDate.length > 0
  const isNextApplicableDate = nextApplicableDate.length > 0 && !isInRangeCurrentDate

  if (isInRangeCurrentDate) {
    milliseconds = inRangeCurrentDate[0][0]
    currentStartItemDate = formatEventDate(currentDate)
    currentEndItemDate = inRangeCurrentDate[0][3]
    isOngoing = true
  }

  if (isNextApplicableDate) {
    milliseconds = nextApplicableDate[0][0]
    currentStartItemDate = nextApplicableDate[0][2]
    currentEndItemDate = nextApplicableDate[0][3]
  }

  if (!isInRangeCurrentDate && !isNextApplicableDate) {
    milliseconds = startDateMilliseconds
    currentStartItemDate = formatEventDate(getDateAndTimeValues(startDate).dateObj)
    currentEndItemDate = formatEventDate(new Date(individualDates.at(-1).utcEventEndDate))
  }

  return { milliseconds, currentStartItemDate, isOngoing, currentEndItemDate }
}

export const getCurrentDateFromIndividualDates = ({
  currentDate,
  currentDateMiliseconds,
  itemIndividualDates,
  startDate,
  endDate,
}: {
  currentDate: Date
  currentDateMiliseconds: number
  itemIndividualDates: IndividualDateType[]
  startDate: string
  endDate: string
}): {
  currentStartItemDate: string
  milliseconds: number
  currentEndItemDate: string
  isOngoing: boolean
  startDateMilliseconds: number
  endDateMilliseconds: number
} => {
  const individualDates: CustomIndividualDates[] = itemIndividualDates?.map((individualDate: IndividualDateType) => [
    new Date(individualDate.utcEventStartDate).getTime(),
    new Date(individualDate.utcEventEndDate).getTime(),
    formatEventDate(new Date(individualDate.utcEventStartDate)),
    formatEventDate(new Date(individualDate.utcEventEndDate)),
  ])
  const inRangeCurrentDate: CustomIndividualDates[] = []
  const nextApplicableDate: CustomIndividualDates[] = []
  individualDates
    ?.sort((a, b) => (a[0] > b[0] ? 1 : -1))
    ?.forEach(individualDate => {
      const startIndividualDate = individualDate[0]
      const endIndividualDate = individualDate[1]

      if (currentDateMiliseconds < startIndividualDate) {
        nextApplicableDate.push(individualDate)
      }

      if (currentDateMiliseconds >= startIndividualDate && currentDateMiliseconds <= endIndividualDate) {
        inRangeCurrentDate.push(individualDate)
      }
    })

  const startDateMilliseconds =
    inRangeCurrentDate?.[0]?.[0] || nextApplicableDate?.[0]?.[0] || new Date(getDateAndTimeValues(startDate).dateObj).getTime()
  const endDateMilliseconds =
    inRangeCurrentDate?.[0]?.[1] || nextApplicableDate?.[0]?.[1] || new Date(getDateAndTimeValues(endDate).dateObj).getTime()

  const { milliseconds, currentStartItemDate, currentEndItemDate, isOngoing } = getMilliSecondsAndDate({
    inRangeCurrentDate,
    nextApplicableDate,
    currentDate,
    currentDateMiliseconds,
    startDateMilliseconds,
    startDate,
    individualDates: itemIndividualDates,
  })
  return { milliseconds, currentStartItemDate, currentEndItemDate, isOngoing, startDateMilliseconds, endDateMilliseconds }
}

export const modifyUpcomingEvent = (item: EventsDataItemType): EventsDataItemType => {
  const currentDate = new Date()
  const currentDateMiliseconds = currentDate.getTime()

  const {
    milliseconds = new Date(item.utcEventStartDate).getTime(),
    currentStartItemDate = null,
    isOngoing = undefined,
    startDateMilliseconds = new Date(item.utcEventStartDate).getTime(),
    endDateMilliseconds = new Date(item.utcEventEndDate).getTime(),
  } = item.individualDates
    ? getCurrentDateFromIndividualDates({
        currentDate,
        itemIndividualDates: item.individualDates,
        startDate: item.startDate,
        endDate: item.endDate,
        currentDateMiliseconds,
      })
    : {}

  const onGoingContiguousEvent = !item.individualDates && currentDateMiliseconds <= new Date(item.utcEventEndDate).getTime()

  if (currentDateMiliseconds < startDateMilliseconds) {
    item.milliseconds = startDateMilliseconds
    item.currentDate = formatEventDate(new Date(startDateMilliseconds))
  } else {
    item.milliseconds = onGoingContiguousEvent ? currentDateMiliseconds : milliseconds
    item.currentDate =
      currentDateMiliseconds <= endDateMilliseconds
        ? currentStartItemDate || formatEventDate(currentDate)
        : formatEventDate(new Date(getDateAndTimeValues(item.startDate).dateObj))
  }

  item.isOngoing = isOngoing || onGoingContiguousEvent
  return item
}

import './news.scss'

import { listTemplate, newsItemsTemplate } from './newslist'
import { NewsConfigType, NewsDataType } from './newsTypes'

export const itemsTemplate = (data: NewsDataType, config: NewsConfigType) => {
  return newsItemsTemplate(data, config)
}

export const template = (data: NewsDataType, config: NewsConfigType) => {
  return listTemplate(data, config)
}

import './related-jobs.scss'

type RelatedJobsDataItemType = {
    title: string
    primaryLocation: string
    jobFunction: string
    url: string
}

type RelatedJobsDataType = {
    items: RelatedJobsDataItemType[],
    jobListingUrl: string,
    totalNumber: number
}

type RelatedJobsConfigType = {
    translations: {
        jobListingTitleText: string
        jobListingButtonText: string
    }
}

const preTitleTemplate = (item: RelatedJobsDataItemType, formattedLocation: string) =>
    item.jobFunction
        ? `<p class="c-filterable-list__related-job-pretitle">${formattedLocation} | ${item.jobFunction}</p>` : ''

const jobTitleTemplate = (item: RelatedJobsDataItemType) =>
    item.url
        ? `<a class="c-filterable-list__related-job-title-link icon-briefcase" href="${item.url}">
                <span class="c-filterable-list__related-job-title-text">${item.title || ''}</span>           
           </a>`
        : ''

const seeAllRelatedJobsButtonTemplate = (data: RelatedJobsDataType, config: RelatedJobsConfigType) =>
    data.totalNumber > 6
        ? ` <div class="c-filterable-list__related-jobs-see-all-button">
                <button class="cmp-button" onclick="(window.location='${data.jobListingUrl}')">
                    ${config.translations.jobListingButtonText}
                    <span class="cmp-button__icon icon-keyboard_arrow_right icon--sm"></span>
                </button> 
            </div>`
        : ''

export const itemsTemplate = (data: RelatedJobsDataType) => {
    return `${data.items
        ?.map(item => {
            const formattedLocation = item.primaryLocation
                .split('\n')
                .map(item => item.split(' ,').join(', '))
                .join('<br />')
            return `
    <li class="c-filterable-list__related-job">
        <div class="c-filterable-list__related-job-content">
            ${preTitleTemplate(item, formattedLocation)}
            ${jobTitleTemplate(item)}
        </div>
        <a class="c-filterable-list__related-job-link" href="${item.url}"></a>
    </li>
`
        })
        .join('')}`
}

export const template = (data: RelatedJobsDataType, config: RelatedJobsConfigType): string => `
  <div class="c-filterable-list__related-jobs-wrapper">
    <div class="c-filterable-list__related-jobs-title">${config.translations.jobListingTitleText}</div>
    <ul class="c-filterable-list__related-jobs-content">${itemsTemplate(data)}</ul>
    ${seeAllRelatedJobsButtonTemplate(data, config)}
  </div>
  `


import { EventsConfigType, EventsDataItemType, EventsDataType } from '@components/filterable-list/list/templates/events/eventsTypes'
import { toCamelCase } from '@site/js/utils/camelCase'

import { eventState, updateState } from '../events-state'
import { calendarIcon, checkDelay, dateLine, locationLine, renderSectionDivider } from '../ui/events-ui'
import { modifyUpcomingEvent } from './upcoming-events'

/**
 * list items template
 * @param {*} data
 * @param {*} config
 */

// eslint-disable-next-line sonarjs/cognitive-complexity
export const listItemsTemplate = (data: EventsDataType, config: EventsConfigType) => {
  const isTopEvent = item => data.topItem !== null && JSON.stringify(data.topItem) === JSON.stringify(item)
  const eventListTemplate = item => `
    <a href="${item.url}" class="c-filterable-list__link">
      <div class="c-filterable-list__events-decoration">
         ${calendarIcon({
           startDate: item.startDate,
           endDate: item.endDate,
           isOnDemand: item.onDemand,
           isUnscheduledEvent: item.unscheduledEvent,
           unscheduledEventsUpcomingLabel: config.translations.unscheduledEventsUpcomingLabel,
         })}
       </div>
       <div class="c-filterable-list__events-content">
         <div class="c-filterable-list__events-content-top">
            <p class="c-filterable-list__events-date">
             ${[
               item.unscheduledEvent ? config.translations.unscheduledEventsDateLabel : dateLine(item.startDate, item.endDate, item.onDemand),
               locationLine(item.location, item.onDemand, config.translations.onDemandLocation),
             ]
               .filter(Boolean)
               .join(' | ')}
           </p>
           ${config.simple === 'true' ? '' : checkDelay(item, config, isTopEvent(item))}
         </div>
         <h3 class="c-filterable-list__events-title">${item.title}</h3>
         <p class="c-filterable-list__events-description">
           ${item.description || ''}</p>
       </div>
    </a>
    `
  const markup = (item: EventsDataItemType): string => `
   <li class="c-filterable-list__events-row" data-item-link="${item.url}">
        ${eventListTemplate(item)}
   </li>`

  const topEventAsTeaser = (item: EventsDataItemType): string => `
    <li class="c-filterable-list__events-row teaser-top-event" data-item-link="${item.url}">
      <div class="c-filterable-list__events-image ${item.image ? 'cmp-responsive-bg-image--lazy' : 'no-image'}" ${
        item.image ? `data-bg-image-src='${JSON.stringify(item.image)}'` : ''
      }>
        <div class="c-filterable-list__events-decoration">
          ${calendarIcon({
            startDate: item.startDate,
            endDate: item.endDate,
            isOnDemand: item.onDemand,
            isUnscheduledEvent: item.unscheduledEvent,
            unscheduledEventsUpcomingLabel: config.translations.unscheduledEventsUpcomingLabel,
          })}
             <div class="c-filterable-list__events-location ${item.image ? 'c-filterable-list__events-location__color' : ''}">
                <div class="c-filterable-list__events-location-city">${item.city}</div>
                <div class="c-filterable-list__events-location-country">${item.country}</div>
            </div>
        </div>
      </div>

      <div class="c-filterable-list__events-content">
       <div class="marija">
            ${config.simple === 'true' ? '' : checkDelay(item, config, isTopEvent(item))}
            <p class="c-filterable-list__events-date">
             ${item.dayOfTheWeek ? item.dayOfTheWeek : ''}, ${dateLine(item.startDate, item.endDate, item.onDemand)}
            </p>
       </div>
        <h3 class="c-filterable-list__events-title">${item.title}</h3>
        <p class="c-filterable-list__events-description">
         ${item.description || ''}
        </p>
        <a class="cmp-button c-filterable-list__events-link"
              href="${item.url}"
              aria-label="${config.translations.topEventLinkText}: ${item.title}"
              data-dm="${config.componentName}.link">
              <span class="cmp-button__text">${config.translations.topEventLinkText}</span>
              <span class="cmp-button__icon cmp-button__icon--keyboard_arrow_right"></span>
        </a>
      </div>
   </li>
`

  let topEvent = ''
  let template = ''

  if (config.eventListType) {
    updateState({ viewMode: toCamelCase(config.eventListType) })
  }

  const isScheduledEvent = (item: EventsDataItemType): boolean => !item.onDemand && !item.unscheduledEvent

  const upcomingEvents = (
    items: EventsDataItemType[],
    actionCallback: (item: EventsDataItemType) => EventsDataItemType,
    filterCallback?: (item: EventsDataItemType) => boolean,
  ) =>
    items
      .filter(filterCallback ? filterCallback : () => true)
      .map(actionCallback)
      .sort((a, b) => (a?.milliseconds > b?.milliseconds ? 1 : -1))

  const dataItems =
    (!eventState.isOnDemandSection && !eventState.isUnscheduledEventSection && eventState.viewMode === 'ondemandEvents') ||
    eventState.viewMode === 'upcomingEvents'
      ? [
          ...data.items.filter((item: EventsDataItemType) => item.unscheduledEvent || item.onDemand),
          ...upcomingEvents(data.items, modifyUpcomingEvent, isScheduledEvent),
        ]
      : data.items

  dataItems.forEach((item: EventsDataItemType) => {
    const itemDate = eventState.viewMode === 'pastEvents' ? item.endDate : item.currentDate
    const dataItem = {
      date: itemDate,
      isOnDemand: item.onDemand,
      config,
      isUnscheduledEvent: item.unscheduledEvent,
    }

    if (isTopEvent(item)) {
      topEvent =
        config.displayTopEventAsTeaser === 'true'
          ? `${renderSectionDivider({ ...dataItem, isTopEvent: true })} ${topEventAsTeaser(item)}`
          : markup(item)
    } else {
      template = `${template} ${renderSectionDivider({
        ...dataItem,
        currentDate: item.currentDate,
        isTopEvent: false,
      })} ${markup(item)}`
    }
  })

  return topEvent + template
}

/**
 * list template
 * @param {object} data
 * @param {object} config
 */
export const listTemplate = (data: EventsDataType, config: EventsConfigType): string => {
  updateState({
    [`lastStartDate_${config.randomListId}`]: '',
    isOnDemandSection: false,
  })
  return `
 <div class="c-filterable-list__events c-filterable-list__list">
   <ul class="c-filterable-list__events-list" ref="list">
     ${listItemsTemplate(data, config)}
   </ul>
 </div>
`
}

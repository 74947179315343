import './products.scss'

import { Image, ImageProperties } from '@components/image/image'

type ProductDataItemType = {
  title: string
  link: string
  summary?: string
  url?: string
  description?: string
  type?: string
  image?: ImageProperties
  tags?: string[]
}

type ProductsDataType = {
  items: ProductDataItemType[]
  results: string
}

type ProductsConfigType = {
  translations: {
    [key: string]: string
  }
  componentName?: string
  screenDisplayResults?: string
  listStyle?: string
  teaserColumns?: string
}

const isProductListTeaser = (config: ProductsConfigType) => config.listStyle === 'teaser'

const productItemImageTemplate = (image: ImageProperties) => (image ? `<div class="c-filterable-list__product--image">${Image(image)}</div>` : '')

const productItemTagsTemplate = (tags: string[]): string => {
  if (tags.length) {
    const tagsTemplate = tags
      .map(tag => {
        return `<div class="c-filterable-list__product--taglist-item">${tag}</div>`
      })
      .join('')
    return `<div class="c-filterable-list__product--taglist">${tagsTemplate}</div>`
  }
  return ''
}

const productItemTitleTemplate = (title: string): string => (title ? `<div class="c-filterable-list__product--title">${title}</div>` : '')

const productItemDescriptionTemplate = (description: string): string =>
  description ? `<div class="c-filterable-list__product--description">${description}</div>` : ''

const productItemLinkTemplate = (config: ProductsConfigType, item: ProductDataItemType) =>
  item.url
    ? `
    <a class="cmp-button c-filterable-list__product--link" href="${item.url}"
      aria-label="${config.translations?.readMoreAriaLabel}" data-dm="${config.componentName}.link">
      <span class="cmp-button__text">${config.translations?.readMoreLabel}</span>
      <span class="cmp-button__icon cmp-button__icon--keyboard_arrow_right"></span>
    </a>
  `
    : ''

export const itemsTemplate = (data: ProductsDataType, config: ProductsConfigType) => `
  ${data.items
    .map(item =>
      isProductListTeaser(config)
        ? `<div class="c-filterable-list__product" onclick="(window.location='${item.url}')">
            ${productItemImageTemplate(item.image)}
            <div class="c-filterable-list__product--content">
              ${productItemTagsTemplate(item.tags)}
              ${productItemTitleTemplate(item.title)}
              ${productItemDescriptionTemplate(item.description)}
              ${productItemLinkTemplate(config, item)}
            </div>
          </div>`
        : `
    <tr onclick="(window.location = '${item.link}')" class="c-filterable-list__table-row">
      <td class="c-filterable-list__table-td">
        <a data-dm="${config.componentName}.link" href="${item.link}" class="c-filterable-list__link">${item.title}</a>
      </td>
      <td class="c-filterable-list__table-td" >${item.summary || '-'}</td>
    </tr>
`,
    )
    .join('')}`

export const template = (data: ProductsDataType, config: ProductsConfigType) =>
  isProductListTeaser(config)
    ? `<div class="c-filterable-list__products-teasers c-filterable-list__products-teasers--column-${config.teaserColumns || 3}" ref="list">
      ${itemsTemplate(data, config)}
      </div>`
    : `
  <div class="c-filterable-list__products" >
    <p class="c-filterable-list__results">
      ${((config.translations || {}).screenDisplayResults || '').replace('{0}', data.results)}
    </p>
    <table ref="table" class="c-filterable-list__table">
      <thead class="c-filterable-list__table-thead">
        <tr class="c-filterable-list__table-row">
          <th scope="col" class="c-filterable-list__table-th">${config.translations.typeColumn || 'Type'}</th>
          <th scope="col" class="c-filterable-list__table-th">${config.translations.descriptionColumn || 'description'}</th>
        </tr>
      </thead>
      <tbody ref="list" class="c-filterable-list__table-tbody">
        ${itemsTemplate(data, config)}
      </tbody>
    </table>
  </div>`

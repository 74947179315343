import { version } from 'react'
import ReactDOM from 'react-dom/client'

export default function register(root, fn) {
  const rootElement = typeof root === 'string' ? document.getElementById(root) : root

  const render = rootElement => {
    fn((component, callback = () => {}) => {
      if (version.match(/18\.+/i)) {
        setTimeout(callback, 0)
        return ReactDOM.createRoot(rootElement).render(component)
      }
      return ReactDOM.render(component, rootElement, callback)
    }, rootElement)
  }

  if (rootElement) {
    // Allowing us to target DOM elements. Eg. <image-wrapper>
    const isInstanceOfHTMLCollection = Boolean(rootElement instanceof HTMLCollection)

    if (!isInstanceOfHTMLCollection && (rootElement.length === 1 || rootElement.length === undefined)) {
      return render(rootElement)
    }

    const collections = Array.from(rootElement)
    return collections.map(rel => render(rel))
  }
}

/* eslint-disable max-lines */
/* eslint-disable sonarjs/cognitive-complexity */
import { LAST_ACTIVE_FILTER } from '@react/ListWrapper/constants'
import { useState } from 'react'

import { AnalyticsType, FilterField, SelectedFilter, SubFilterSelectedItemType, TranslationsType } from '../Filters/FiltersTypes'
import { addFilter, handleClearActiveFilter, removeFilter, updateSelectedFilters } from '../helpers'

type useFilterbarProps = {
  analytics: AnalyticsType
  translations: TranslationsType
  selectedFiltersProp: SelectedFilter[]
  filterFields: FilterField[]
  secondaryFilterFields: FilterField[]
  totalNumber: number
  onClearFilters?: () => void
}

export function useFilterbar({
  analytics,
  translations,
  selectedFiltersProp,
  filterFields,
  secondaryFilterFields,
  totalNumber,
  onClearFilters,
}: useFilterbarProps) {
  const [selectedFilters, setSelectedFilters] = useState(selectedFiltersProp || [])
  const [selectedFilterState, setSelectedFilterState] = useState(null)
  const [isFilterbarOpened, setIsFilterbarOpened] = useState(false)
  const [translationsState, setTranslationsState] = useState(translations)
  const [analyticsState, setAnalyticsState] = useState(analytics)
  const [selectButtonTitle, setSelectButtonTitle] = useState('')
  const [headerTitle, setHeaderTitle] = useState('')
  const [activeDropdowns, setActiveDropdowns] = useState([])
  const [isToggled, setIsToggled] = useState(false)
  const [activeFiltersState, setActiveFiltersState] = useState([])
  const [filterFieldsState, setFilterFieldsState] = useState(filterFields || [])
  const [secondaryFilterFieldsState, setSecondaryFilterFieldsState] = useState(secondaryFilterFields || [])
  const [totalNumberState, setTotalNumberState] = useState(totalNumber || 0)

  const initialSelectedFiltersState = [
    {
      key: 'offset',
      value: ['0'],
    },
    {
      key: 'calculatedOffset',
      value: ['0'],
    },
  ]

  const handleFilterChange = ({
    key,
    value,
    type,
    subFilter,
  }: {
    key: string
    value: string[]
    type?: string
    subFilter?: SubFilterSelectedItemType
  }) => {
    if (type?.toLocaleLowerCase() === 'rangeslider') {
      handleRangeSliderFilterChange(key, value, subFilter)
    } else {
      const hasValue = value?.length
      const existingFilter = selectedFilters?.find(filterItem => filterItem.key === key)
      if (existingFilter) {
        if (hasValue) {
          if (existingFilter.value !== value) {
            updateSelectedFilters({ key, value, selectedFilters, setSelectedFilters })
          }
        } else {
          removeFilter({ key, selectedFilters, setSelectedFilters })
        }
      } else {
        if (hasValue) {
          addFilter({ key, value, selectedFilters, setSelectedFilters })
        }
      }
    }
  }

  const handleRangeSliderFilterChange = (key: string, value: string[], subFilter?: SubFilterSelectedItemType) => {
    const hasValue = value?.length
    const hasSubFilterValue = subFilter && subFilter.value[0] !== ''
    const filtersToBeApplied = []
    const existingFromRange = selectedFilters.find((item: SelectedFilter) => item.key === `${key}.from`)
    const existingToRange = selectedFilters.find((item: SelectedFilter) => item.key === `${key}.to`)
    const existingRangeType = subFilter ? selectedFilters.find((item: SelectedFilter) => item.key === `${subFilter.key}`) : null

    let selectedFiltersWithoutRangeSlider = selectedFilters.filter(
      item => item.key !== `${key}.from` && item.key !== `${key}.to` && item.key !== `${key}Type`,
    )

    if (!existingFromRange && hasValue) {
      filtersToBeApplied.push({
        key: `${key}.from`,
        value: [value[0]],
      })
    }

    if (existingFromRange) {
      if (hasValue) {
        existingFromRange.value[0] = value[0]
        filtersToBeApplied.push(existingFromRange)
      } else {
        selectedFiltersWithoutRangeSlider = selectedFiltersWithoutRangeSlider.filter(selectedFilter => selectedFilter.key !== `${key}.from`)
      }
    }

    if (!existingToRange && hasValue) {
      filtersToBeApplied.push({
        key: `${key}.to`,
        value: [value[1]],
      })
    }

    if (existingToRange) {
      if (hasValue) {
        existingToRange.value[0] = value[1]
        filtersToBeApplied.push(existingToRange)
      } else {
        selectedFiltersWithoutRangeSlider = selectedFiltersWithoutRangeSlider.filter(selectedFilter => selectedFilter.key !== `${key}.to`)
      }
    }

    if (!existingRangeType && hasSubFilterValue) {
      filtersToBeApplied.push({
        key: `${key}Type`,
        value: [subFilter.value[0]],
      })
    }

    if (existingRangeType) {
      if (hasSubFilterValue) {
        existingRangeType.value = subFilter.value
        filtersToBeApplied.push(existingRangeType)
      } else {
        selectedFiltersWithoutRangeSlider = selectedFiltersWithoutRangeSlider.filter(selectedFilter => selectedFilter.key !== subFilter.key)
      }
    }

    addRangeFilter(selectedFiltersWithoutRangeSlider, filtersToBeApplied)
  }

  const addRangeFilter = (selectedFiltersWithoutRangeSlider: SelectedFilter[], filtersToBeAdded: SelectedFilter[]) =>
    setSelectedFilters([...selectedFiltersWithoutRangeSlider, ...filtersToBeAdded])

  const handleClearAllFilters = () => {
    setSelectedFilters(initialSelectedFiltersState)
    setActiveDropdowns([])
    sessionStorage.setItem(LAST_ACTIVE_FILTER, '')
    if (onClearFilters) {
      onClearFilters()
    }
  }

  const handleClearFilter = ({
    key,
    value,
    type,
    subFilter,
  }: {
    key: string
    value: string[]
    type?: string
    subFilter?: SubFilterSelectedItemType
  }) => {
    handleClearActiveFilter({
      key,
      value,
      type,
      subFilter,
      selectedFilters,
      activeFiltersState,
      handleFilterChange,
    })
  }

  return {
    activeDropdowns,
    activeFiltersState,
    analyticsState,
    filterFieldsState,
    isFilterbarOpened,
    isToggled,
    headerTitle,
    secondaryFilterFieldsState,
    selectButtonTitle,
    selectedFilters,
    selectedFilterState,
    totalNumberState,
    translationsState,
    handleClearAllFilters,
    handleClearFilter,
    handleFilterChange,
    setActiveDropdowns,
    setActiveFiltersState,
    setAnalyticsState,
    setFilterFieldsState,
    setHeaderTitle,
    setIsFilterbarOpened,
    setIsToggled,
    setTranslationsState,
    setSecondaryFilterFieldsState,
    setSelectButtonTitle,
    setSelectedFilters,
    setSelectedFilterState,
    setTotalNumberState,
  }
}

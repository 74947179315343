import './references.scss'

import { Image, ImageProperties } from '@components/image/image'

type ItemType = {
  disabled: boolean
  key: string
  selected: boolean
  value: string
}

type ItemDataType = {
  description: string
  image: ImageProperties
  properties: { [key: string]: string[] }
  title: string
  url: string
}

type DynamicFieldType = {
  key: string
  value: string
}

type FilterFieldsType = {
  items: ItemType[]
  key: string
  label: string
  multiselect: boolean
  type: string
}

type ReferencesDataType = {
  calculatedOffset: number
  dynamicFields: DynamicFieldType[]
  filterFields: FilterFieldsType[]
  items: ItemDataType[]
  loadMore: boolean
  secondaryFilterFields?: FilterFieldsType[] | null
  title: string
  totalNumber: number
}

type ReferencesConfigType = {
  componentName: string
  displaysortorder: string | boolean
  dm: string
  listStyle: string
  loadMore: string | boolean
  randomListId: number
  teaserColumns: string
  translations: {
    [key: string]: string
  }
  type: string
  url: string
}

const tableDynamicFieldTemplate = (dynamicField: DynamicFieldType) => `<th class="c-filterable-list__table-th" scope="col">${dynamicField.value}</th>`

const tableItemsTemplate = (data: ReferencesDataType, config: ReferencesConfigType) => `
  ${data.items
    .map(
      item => `
    <tr class="c-filterable-list__table-row">
      ${data.dynamicFields
        .map(
          (field, index) => `<td class="c-filterable-list__table-td ${field.key === 'title' ? 'c-filterable-list__table-td__title' : ''}">
          <a href="${item.url}"
            class="c-filterable-list__reference-link"
            aria-label="${config.translations?.readMoreLabel}: ${item.title}"
            data-dm="${config.componentName}.link"
            ${index === 0 ? '' : 'tabindex="-1"'}></a>
            ${item.properties[field.key] ? item.properties[field.key].join(', ') || '-' : ''}
        </td>`,
        )
        .join('')}
    </tr>
`,
    )
    .join('')}`

const tableTemplate = (data: ReferencesDataType, config: ReferencesConfigType) => `
  <div class="c-filterable-list__references" >
    <p class="c-filterable-list__results"></p>
    <div class="c-filterable-list__table-wrapper">
      <table ref="table" class="c-filterable-list__table">
        <thead class="c-filterable-list__table-thead">
          <tr class="c-filterable-list__table-row">
            ${data.dynamicFields.map(field => tableDynamicFieldTemplate(field)).join('')}
          </tr>
        </thead>
        <tbody ref="list" class="c-filterable-list__table-tbody">
          ${tableItemsTemplate(data, config)}
        </tbody>
      </table>
    </div>
  </div>
  `

const teaserItemsTemplate = (data: ReferencesDataType, config: ReferencesConfigType) =>
  `${data.items
    .map(item => {
      return `<li class="c-filterable-list__reference-teaser cmp-teaser" data-item-link="${item.url}">
      <div class="cmp-teaser__image">
        ${item.image ? Image(item.image) : ''}
      </div>
      <div class="c-filterable-list__reference-content cmp-teaser__content">
        <div class="c-filterable-list__reference-title" tabindex="0">
          <h3 class="cmp-teaser__title-text cmp-teaser__title">${item.title}</h3>
        </div>
        <div class="c-filterable-list__reference-description cmp-teaser__description" tabindex="0">
          <p>${item.description || ''}</p>
        </div>
        <div class="cmp-teaser__action-container">
          <a href="${item.url}" aria-label="${config.translations?.readMoreLabel}: ${
        item.title
      }" class="cmp-teaser__action-link cmp-button" data-dm="casestudy.link">
            <span class="cmp-button__text">${config.translations?.readMoreLabel}</span>
            <span class="cmp-button__icon cmp-button__icon--keyboard_arrow_right" aria-hidden="true"/>
          </a>
        </div>
      </div>
      <a href="${item.url}" class="c-filterable-list__reference-link" aria-hidden="true" data-dm="casestudy.link" tabindex="-1"></a>
    </li>
`
    })
    .join('')}`

const teaserTemplate = (data: ReferencesDataType, config: ReferencesConfigType) => `
  <div class="c-filterable-list__references" >
    <p class="c-filterable-list__results"></p>
    <ul class="c-filterable-list__teaser-wrapper c-filterable-list__teaser-wrapper--column-${config.teaserColumns}" ref="list">
      ${teaserItemsTemplate(data, config)}
    </ul>
  </div>
  `

export const itemsTemplate = (data: ReferencesDataType, config: ReferencesConfigType) => {
  return config.listStyle === 'table' ? tableItemsTemplate(data, config) : teaserItemsTemplate(data, config)
}

export const template = (data: ReferencesDataType, config: ReferencesConfigType) => {
  return config.listStyle === 'table' ? tableTemplate(data, config) : teaserTemplate(data, config)
}

/* eslint-disable max-lines */
import './filterable-list.scss'

import { cls } from '@react/utils/classname'
import { arrayOf, exists, isSafari, parseJson, selectAll, setRefs } from '@site/js/utils/utils'

import { loadingSpinnerSvg } from '../loading-spinner/loading-spinner'
import { Service } from '../service/service'
import { sliders } from '../slider/slider'
import { UrlHandler } from '../urlHandler/urlHandler'
import {
  EditableLinksType,
  FilterableListConfigType,
  FilterableListElementType,
  FilterableListResponseDataType,
  FiltersType,
  LinkDataType,
  UpdateElementType,
} from './filterableListTypes'
import { List, ListConfigType, ListDataType } from './list/list'
import { countLines } from './list/templates/events/carousel/events-carousel'
import { eventState, updateState } from './list/templates/events/events-state'
import { LoadMore } from './load-more/load-more'

const editableLinksTemplate = (editableLinks: string) => {
  const parsedEditableLinks: EditableLinksType[] = JSON.parse(editableLinks)
  let linksTemplate = ''
  if (parsedEditableLinks.length) {
    parsedEditableLinks.forEach(parsedEditableLink => {
      linksTemplate += `<a href='${parsedEditableLink.link}' class="c-filterable-list__load-more-button c-filterable-list__button-secondary">
            ${parsedEditableLink.linkText}
            <span class="c-filterable-list__icon icon-keyboard_arrow_right icon--md"></span>
          </a>`
    })
  }
  return linksTemplate
}

const loadMoreTemplate = (config: FilterableListConfigType) => {
  return `
  <div class="c-filterable-list__load-more">
          <div class="c-filterable-list__load-more-spinner"></div>
          <button data-dm="${
            config.componentName
          }.button" ref="loadMore" class="c-filterable-list__load-more-button c-filterable-list__button-secondary">
            ${config.translations?.loadMore || 'Load More'}
            <span class="c-filterable-list__icon icon-keyboard_arrow_down icon--sm"></span>
          </button>
        </div>`
}

/**
 * layout template
 * @param {object} config
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
const template = (config: FilterableListConfigType) => `
    ${
      config.hasHeader === 'true'
        ? `<div class="c-filterable-list__heading">
      <h2 class="c-filterable-list__heading-title">
        <span class="icon-calendar icon--sm"></span>
        ${config.translations.listTitle}
      </h2>
    </div>`
        : ''
    }
    <div ref="filterbar" class="c-filterable-list__filterbar ${config.filterType === 'quickfilter' ? 'quickfilter' : ''}"></div>
    <div ref="list" class="c-filterable-list__list">
      <div class="c-filterable-list__loading">${loadingSpinnerSvg}</div>
    </div>
    <div
      class="${cls({
        'c-filterable-list__buttons-wrapper': true,
        'link--is-left-aligned':
          config?.linkAlignment === 'left' || config?.buttonsAlignment === 'left' || (config?.type === 'event' && config?.eventsPageLink),
        'link--is-center-aligned': config?.linkAlignment === 'center' || config?.buttonsAlignment === 'center',
      })}"
    >
      ${config.type !== 'filterableList' && config.loadMore === 'true' ? loadMoreTemplate(config) : ''}
      ${config.type === 'filterableList' && config.linkType === 'loadMore' ? loadMoreTemplate(config) : ''}
      ${config.type === 'filterableList' && config.linkType === 'editableLinks' ? editableLinksTemplate(config.editableLinks) : ''}

      ${exists(config.seeAllLink) ? renderLink(config.seeAllLink, config.translations.filterbarShowAll) : ''}
      ${exists(config.links) ? generateLinks(config.links) : ''}
      ${exists(config.eventsPageLink) && config.carousel !== 'true' ? renderLink(config.eventsPageLink, config.translations.seeAll) : ''}
    </div>
    ${config.pagination === 'true' ? '<div ref="pagination" class="c-filterable-list__pagination"></div>' : ''}
  `

/**
 * link layout template
 */
const renderLink = (link: string, label: string) => `
  <div class="c-filterable-list__additional-link">
    <a class="c-filterable-list__button-secondary" href="${link}" data-dm="news.link">
      <span>${label}</span>
      <span class="c-filterable-list__icon icon-keyboard_arrow_right icon--sm"></span>
    </a>
  </div>
`

/**
 * link layout template
 * @param array of links
 */
const generateLinks = (links: string) => {
  const linkData: LinkDataType[] = links === undefined ? [] : JSON.parse(links)
  let template = ''

  linkData
    ?.map(link => {
      template = template + renderLink(link.link, link.label)
    })
    .join('')
  return template
}

/**
 * Filterable list component
 * @param {HTMLElement} el
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
export const FilterableList = (el: FilterableListElementType, data?: FilterableListResponseDataType) => {
  el.classList.add('c-filterable-list')

  // configurations
  const config: FilterableListConfigType = (el.config = {
    ...el.dataset,
    ...(el.dataset.analytics && {
      analytics: parseJson(el.dataset.analytics),
    }),
    translations: parseJson(el.dataset.translations),
    randomListId: new Date().getTime(),
  })

  el.classList.add(`c-filterable-list--${config.style || 'list'}`)

  // render layout
  el.innerHTML = template(config)

  setRefs(el)

  // url handler
  const urlHandler = UrlHandler({
    prefix: `filterable${config.listId}-`,
  })

  // data service
  const service = Service({
    url: config.url,
  })

  const selectedFilters: FiltersType = parseJson(config?.selectedFilters)

  /**
   * components
   */
  const list = el.refs.list?.map(List(config as ListConfigType))
  const paginator =
    config.loadMore === 'true'
      ? el.refs.loadMore?.map(LoadMore({ offset: Number(selectedFilters.offset?.[0]) || 0, loadMore: data?.loadMore || false }))
      : []
  /**
   * adds the current page to the filter params object
   * @param {object} filters
   */
  el.addCurrentOffsetTo = (filters: FiltersType) => (filters.offset = [paginator ? String(paginator[0].getOffset()) : '0'])

  /**
   * adds the calculatedOffset to the filter params object
   * @param {object} filters
   */
  el.addCalculatedOffsetTo = (filters: FiltersType) => (filters.calculatedOffset = [String(paginator?.[0].getCalculatedOffset())])

  /**
   * adds the on demand flag to the filter params object
   * @param {object} filters
   */
  el.addOnDemandFlagTo = (filters: FiltersType) => {
    if (paginator && paginator[0] && (filters.onDemand || paginator[0].getIsOnDemand())) {
      filters.onDemand = true
    }
    if (paginator && paginator[0] && !filters.onDemand && !paginator[0].getIsOnDemand()) {
      delete filters.onDemand
    }
  }

  /**
   * updates
   * @param {object}
   */
  el.update = ({ resetPaginator = false, resetEventViewModeState = false }: UpdateElementType = {}) => {
    if (config.type === 'event') selectedFilters.pastEvent = eventState.viewMode === 'pastEvents'

    // add current page to params
    if (config.loadMore === 'true') {
      if (paginator?.[0] && resetPaginator) paginator?.[0].resetOffset()
      el.addCurrentOffsetTo(selectedFilters)
      el.addCalculatedOffsetTo(selectedFilters)
    }

    // reset view mode links flag
    if (resetEventViewModeState) {
      updateState({ isPastOrUpcomingEventLinkRendered: false })
    }

    // add onDemand flag to params
    paginator?.[0] && paginator?.[0].resetOnDemand()
    el.addOnDemandFlagTo(selectedFilters)

    // set url params
    urlHandler.set(selectedFilters)

    // send request
    service
      .get(selectedFilters)
      .then(renderData)
      .then(config.type === 'event' || config.type === 'filterableList' ? getPastEvents(selectedFilters) : '')
  }

  const getPastEvents = (selectedFilters: FiltersType) => {
    selectedFilters.pastEventPresent = true
    service.get(selectedFilters).then(data => updateState({ shouldRenderViewModeLinks: data.pastItemsExist }))
  }

  /**
   * renders data from update request
   * @param {object} data
   */
  const renderData = (data: FilterableListResponseDataType) => {
    if (!data || (config.editMode === 'true' && data.items.length === 0)) {
      el.innerHTML = `<p>${config.translations.emptyListMessage}</p>`
      return
    }
    paginator?.[0]?.update({
      offset: paginator?.[0].updateOffset(data.items),
      loadMore: data.loadMore,
      calculatedOffset: data.calculatedOffset,
    })
    list[0].render(data as ListDataType)

    if (!document.querySelector('.aem-AuthorLayer-Edit')) sliders()

    initViewModeEvents()
  }

  /**
   * load more event handler
   */
  el.loadMore = () => {
    // get selected filters
    return arrayOf(selectedFilters).map(selectedFilter => {
      // add current page to params
      el.addCurrentOffsetTo(selectedFilter)

      // add onDemand flag to params
      el.addOnDemandFlagTo(selectedFilter)

      // add calculatedOffset page to params
      el.addCalculatedOffsetTo(selectedFilter)

      config.type === 'event' ? (selectedFilter.pastEvent = eventState.viewMode === 'pastEvents') : ''

      window.dispatchEvent(
        new CustomEvent('loadmore-filterable-list', {
          detail: {
            selectedFilters: selectedFilter,
            id: config.listId,
          },
        }),
      )
    })
  }

  /**
   * renders data from load more request
   * @param {object} data
   */
  const renderLoadMoreData = (data: FilterableListResponseDataType) => {
    paginator?.[0]?.update({
      offset: paginator?.[0].updateOffset(data.items),
      loadMore: data.loadMore,
      calculatedOffset: data.calculatedOffset,
    })
    // render normally if page 0 otherwise load more
    config.loadMore === 'true' && paginator?.[0]?.getOffset() > 0 ? list[0].render(data as ListDataType, true) : list[0].render(data as ListDataType)
    initViewModeEvents()
  }

  /**
   * paginator change handler
   * - load more or paginate event
   */
  el.onPaginatorChange = () => {
    return config.loadMore === 'true' ? el.loadMore() : el.update()
  }

  /**
   * container size change handler
   */
  const setContainerSize = (container: HTMLDivElement) => {
    const breakpoints = [
      { maxWidth: 599, class: 'filterable-list--sm' },
      { maxWidth: 959, class: 'filterable-list--md' },
      { maxWidth: 1279, class: 'filterable-list--lg' },
    ]
    const rect = container.getBoundingClientRect()
    const allBreakpointClasses = breakpoints.map(breakpoint => breakpoint.class)
    for (const breakpoint of breakpoints) {
      if (Math.trunc(rect.width) < breakpoint.maxWidth) {
        el.classList.remove(...allBreakpointClasses)
        el.classList.add(breakpoint.class)
        break
      }
    }
  }

  /**
   * init click events for past and upcoming events view
   */
  const initViewModeEvents = () => {
    const listRefs = list ? list[0].refs : null
    if (listRefs) {
      if (listRefs.linkPastEventsMode) {
        listRefs.linkPastEventsMode[0].addEventListener('click', () => setViewModeAndUpdate('pastEvents'))
      }
      if (listRefs.linkUpcomingEventsMode) {
        listRefs.linkUpcomingEventsMode[0].addEventListener('click', () => setViewModeAndUpdate('upcomingEvents'))
      }
    }
  }

  /**
   * set view mode and update list
   */
  const setViewModeAndUpdate = (viewMode: string) => {
    updateState({ viewMode })
  }

  /**
   * add event handlers
   */
  paginator?.[0]?.addEventListener('change', el.onPaginatorChange)
  list[0]?.addEventListener('click', (e: Event) => {
    const target = e.target as HTMLElement
    if (list[0].contains(target) && target.classList.contains('js-filterable-list-show-past-events') && !list[0].refs.linkPastEventsMode) {
      setViewModeAndUpdate('pastEvents')
    }
  })

  /**
   * add size handling
   */
  window.addEventListener('resize', () => {
    countLines()
    setContainerSize(el)
  })
  setContainerSize(el)

  renderData(data)

  window.addEventListener('loadmore-template-filterable-list', (e: CustomEvent) => {
    const { data, id } = e.detail
    if (config.listId === id) {
      renderLoadMoreData(data)
    }
  })

  return el
}

const init = () => (selectAll('.filterable-list-component') as FilterableListElementType[]).map(element => FilterableList(element))

window.addEventListener('message', e => {
  if (e.data.task === 'refresh-dynamic-component') {
    init()
  }
})

// This is fix for safari's back-forward cached page state
// https://pgit.atlassian.net/browse/ABB-10859
if (isSafari)
  (function () {
    window.onpageshow = event => {
      if (event.persisted) {
        window.location.reload()
      }
    }
  })()

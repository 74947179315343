import './list.scss'

import {
  RESPONSIVE_BG_IMAGE_SELECTOR,
  RESPONSIVE_IMAGE_SELECTOR,
  ResponsiveBackgroundImage,
  ResponsiveImage,
  ResponsiveImageObserver,
} from '@components/image/responsiveimage/responsiveimage'
import Abbreviation from '@react/Abbreviation'
import { registerReactComponent } from '@react/react-component-registry'
import { initSort } from '@site/js/utils/table-sort'
import { setRefs } from '@site/js/utils/utils'

import { itemsTemplate as eventItems, template as event } from './templates/events/events'
import { itemsTemplate as rtuItems, template as rtuexternallinkselector } from './templates/external-link-selector/external-link-selector'
import { itemsTemplate as jobItems, template as job } from './templates/jobs/jobs'
import { itemsTemplate as newsItems, template as news } from './templates/news/news'
import { itemsTemplate as productItems, template as product } from './templates/products/products'
import { itemsTemplate as referenceItems, template as customersuccesstory } from './templates/references/references'
import { itemsTemplate as relatedJobItems, template as relatedJob } from './templates/related-jobs/related-jobs'
import { itemsTemplate as videoItems, template as video } from './templates/videos/videos'

// templates map
const templates = {
  product,
  event,
  filterableList: event,
  customersuccesstory,
  rtuexternallinkselector,
  fragmentslist: rtuexternallinkselector,
  video,
  news,
  job,
  relatedJob,
}

// items templates map - for load more functionality
const itemsTemplates = {
  product: productItems,
  event: eventItems,
  filterableList: eventItems,
  customersuccesstory: referenceItems,
  fragmentslist: rtuItems,
  video: videoItems,
  news: newsItems,
  job: jobItems,
  relatedJob: relatedJobItems,
}

export type ListConfigType = {
  type?: string
  translations?: {
    [key: string]: string
  }
}

export type ListDataType = {
  items?: {
    [key: string]: string
  }[]
  results?: number
}

export type ListElementType = HTMLDivElement & {
  refs: {
    [key: string]: HTMLDivElement[]
  }
  render: (data?: ListDataType | string, loadMore?: boolean) => void
}

/**
 * Empty state template
 */
const emptyTemplate = (config: ListConfigType): string => `<p>${config.translations?.noResults || 'The list is empty.'}</p>`

/**
 * Error template template
 */
const errorTemplate = (config: ListConfigType): string => `<p>${config.translations?.error || 'Ups! something went wrong.'}</p>`

/**
 * List component
 * @param {object} config
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
export const List = (config: ListConfigType) => (el: ListElementType) => {
  /**
   * Renders the list
   */
  el.render = (data: ListDataType, loadMore: boolean) => {
    if ((data as string) === 'error') {
      el.innerHTML = errorTemplate(config)
    } else if (data.items.length === 0) {
      if (config.type === 'relatedJob') {
        el.remove()
      } else {
        el.innerHTML = emptyTemplate(config)
      }
    } else if (loadMore && config.type !== 'fragmentslist') {
      el.refs.list[0].insertAdjacentHTML('beforeend', itemsTemplates[config.type](data, config))
    } else {
      el.innerHTML = templates[config.type](data, config)
      if (config.type === 'fragmentslist') {
        registerReactComponent('Abbreviation', Abbreviation)
      }
    }

    setRefs(el)

    const lazyImages = Array.from(el.querySelectorAll(`${RESPONSIVE_IMAGE_SELECTOR}`))
    lazyImages.forEach(image => ResponsiveImage(image).init())
    ResponsiveImageObserver(lazyImages)

    const lazyBgImages = Array.from(el.querySelectorAll(`${RESPONSIVE_BG_IMAGE_SELECTOR}`))
    lazyBgImages.forEach(image => ResponsiveBackgroundImage(image).init())
    ResponsiveImageObserver(lazyBgImages)

    /**
     * Set up table sorting for RTU
     */
    if (config.type === 'fragmentslist') initSort(config.type)
  }

  return el
}

import './jobs.scss'

import { cls } from '@react/utils/classname'
import { isMobile } from '@site/js/utils/breakpoint'

type JobsDataType = {
  filterFields: [
    {
      key: string
      label?: string
    },
  ]
  items: {
    title: string
    location: string
    jobType: string
    contractType: string
    experience: string
    jobFunction: string
    url: string
  }[]
}

type JobsConfigType = {
  translations: {
    jobTitle: string
    location: string
  }
}

function getFilterFieldLabelByKey(data: JobsDataType, filterFieldKey: string): string {
  return data.filterFields.find(filterField => filterField.key === filterFieldKey)?.label || filterFieldKey
}

/**
 * table items template
 * - for load more functionality
 * @param {object} data
 */
export const itemsTemplate = (data: JobsDataType) => {
  return `${data.items
    .map(item => {
      const formattedLocation = item.location
        .split('\n')
        .map(item => item.split(' ,').join(', '))
        .join('<br />')
      return `
    <tr data-item-link="${item.url}">
      <td><a class="c-filterable-list__list-link" href="${item.url}">${item.title}</a></td>
      <td>${formattedLocation}</td>
      <td>${item.jobType}</td>
      <td>${item.contractType}</td>
      <td>${item.experience}</td>
      <td>${item.jobFunction}</td>
    </tr>
`
    })
    .join('')}`
}

/**
 * list table template
 * - has a ref="list" for load more functionality
 * @param {object} data
 * @param {object} config
 */
export const template = (data: JobsDataType, config: JobsConfigType): string => `
  <div class="c-filterable-list__jobs-wrapper">
    <table class="c-filterable-list__jobs" ref="list">
      <thead  class="${cls({
        'u-visually-hidden': isMobile,
      })}">        <tr>
          <th scope="col">${config.translations.jobTitle}</th>
          <th scope="col">${config.translations.location}</th>
          <th scope="col">${getFilterFieldLabelByKey(data, 'jobTypeTags')}</th>
          <th scope="col">${getFilterFieldLabelByKey(data, 'contractTypeTags')}</th>
          <th scope="col">${getFilterFieldLabelByKey(data, 'experienceTags')}</th>
          <th scope="col">${getFilterFieldLabelByKey(data, 'jobFunctionTags')}</th>
        </tr>
      </thead>
      <tbody>
        ${itemsTemplate(data)}
      </tbody>
    </table>
  </div>
  `

import './load-more.css'

import { ResponseDataItemType } from '../filterableListTypes'

/**
 * load more button component
 * @param {object} config
 */

export type LoadMoreType = {
  offset?: number
  loadMore?: boolean
  calculatedOffset?: number
}

export type LoadMoreElementType = HTMLDivElement & {
  getOffset?: () => number
  getCalculatedOffset?: () => void
  resetOffset?: () => void
  getIsOnDemand?: () => boolean
  resetOnDemand?: () => void
  updateOffset?: (items?: ResponseDataItemType[] | []) => number
  update?: (pagination: LoadMoreType) => void
}

export const LoadMore =
  ({ offset = 0, loadMore = false, calculatedOffset = 0 }: LoadMoreType) =>
  (el: LoadMoreElementType) => {
    let onDemandMode = false

    /**
     * toggle the load more button
     */
    const toggle = () => (loadMore ? (el.style.display = '') : (el.style.display = 'none'))

    const onClick = () => {
      isLoading(true)

      el.dispatchEvent(new CustomEvent('change'))
    }

    /**
     * sets is loading classname
     * @param {boolean} bool
     */
    const isLoading = (isLoading: boolean) => (isLoading ? el.classList.add('is-loading') : el.classList.remove('is-loading'))

    /**
     * gets the next offset number
     */
    el.getOffset = () => Number(offset)

    /**
     * gets the next offset number
     */
    el.getCalculatedOffset = () => Number(calculatedOffset)

    /**
     * resets the page number
     */
    el.resetOffset = () => {
      offset = 0
      calculatedOffset = 0
    }

    el.getIsOnDemand = () => onDemandMode

    el.resetOnDemand = () => {
      onDemandMode = false
    }

    /**
     * updatse the offset number
     * @param {array} items
     */
    el.updateOffset = (items: [] | ResponseDataItemType[]) => {
      items.forEach(() => {
        offset += 1
      })
      return offset
    }

    /**
     * updates the load more button
     */
    el.update = pagination => {
      offset = pagination.offset
      loadMore = pagination.loadMore
      calculatedOffset = pagination.calculatedOffset
      toggle()
      isLoading(false)
    }

    // events
    el.addEventListener('click', onClick)

    // toggle initial display
    toggle()

    return el
  }

import { EventsConfigType, EventsDataItemType, EventsDataType, LocationLineType } from '@components/filterable-list/list/templates/events/eventsTypes'
import { replaceLabelPlaceholders } from '@site/js/utils/utils'

import { calendarIcon, dateLine, renderSectionDivider } from '../ui/events-ui'

const locationLine = ({ country, city, onDemand = false, requestString = 'Request' }: LocationLineType): string => {
  return onDemand
    ? requestString
    : `<span class="c-filterable-list__events-location--city">${city}</span>
  <span class="c-filterable-list__events-location--country">${country}</span>`
}
export const countLines = () => {
  const eventCarouselItemsTitles = document.querySelectorAll('.c-filterable-list__events-title')
  const newArray = Array.from(eventCarouselItemsTitles)

  newArray.forEach((item: HTMLElement) => {
    const itemsHeight = item.offsetHeight
    const itemsStyle = getComputedStyle(item)
    const itemsLineHeight = parseInt(itemsStyle.lineHeight)
    const countedLines = itemsLineHeight === 0 ? 0 : Math.round(itemsHeight / itemsLineHeight)
    const nextElement = item.nextElementSibling as HTMLElement
    if (nextElement) {
      nextElement.style.display = countedLines >= 4 ? 'none' : '-webkit-box'
    }
  })
}

/**
 * carousel list items template
 * @param {*} data
 * @param {*} config
 */

export const carouselItemsTemplate = (data: EventsDataType, config: EventsConfigType) =>
  data.items
    .map(
      (item: EventsDataItemType, index, items) => `
    ${renderSectionDivider({ date: item.startDate, isOnDemand: item.onDemand, config, isUnscheduledEvent: item.unscheduledEvent })}
    <li class="c-filterable-list__events-row splide__slide
      cmp-carousel__item${index === 0 ? ' cmp-carousel__item--active' : ''}"
      data-item-link="${item.url}"
      role="tab"
      aria-label="${replaceLabelPlaceholders(config.translations.carouselSlideOf, index + 1, items.length)}"
      data-cmp-hook-carousel="item">
      <div class="slide__content">
        <a href="${item.url}"
          class="c-filterable-list__carousel-link"
          aria-label="${config.translations.readMoreLabel}: ${item.title}"
          data-dm="${config.componentName}.link"></a>
        <div class="c-filterable-list__events-wrapper">
        <div class="c-filterable-list__events-card">
          <div class="c-filterable-list__events-header">
          <div class="c-filterable-list__events-image ${item.image ? 'cmp-responsive-bg-image--lazy' : 'no-image'}" ${
            item.image ? `data-bg-image-src='${JSON.stringify(item.image)}'` : ''
          }>
            <div class="c-filterable-list__events-decoration">
              ${calendarIcon({
                startDate: item.startDate,
                endDate: item.endDate,
                isOnDemand: item.onDemand,
                isUnscheduledEvent: item.unscheduledEvent,
                unscheduledEventsUpcomingLabel: config.translations.unscheduledEventsUpcomingLabel,
              })}
              <div class="c-filterable-list__events-location ${item.image ? 'c-filterable-list__events-location__color' : ''}">${locationLine({
                country: item.country,
                city: item.city,
                onDemand: item.onDemand,
                requestString: config.translations.onDemandLocation,
              })}</div>
            </div>
            </div>
            <div class="c-filterable-list__events-content">
              <div class="c-filterable-list__events-content-top">
                <div class="c-filterable-list__events-badge"><p>${item.eventType}</p></div>
                <p class="c-filterable-list__events-date">${item.unscheduledEvent ? config.translations.unscheduledEventsDateLabel : dateLine(item.startDate, item.endDate, item.onDemand)}</p>
              </div>
              <a href="${item.url}" class="c-filterable-list__link"><h3 class="c-filterable-list__events-title">${item.title}</h3></a>
              <p class="c-filterable-list__events-description">${item.description || ''}</p>
              <a class="cmp-button c-filterable-list__events-link"
                href="${item.url}"
                aria-label="${config.translations.readMoreLabel}: ${item.title}"
                data-dm="${config.componentName}.link">
                ${item.webItemInsideIntranetContext ? '<span class="cmp-button__icon icon-link"></span>' : ''}
                <span class="cmp-button__text">${config.translations.readMoreLabel}</span>
                <span class="cmp-button__icon cmp-button__icon--keyboard_arrow_right"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </li>
  `,
    )
    .join('')

/**
 * list template
 * @param {object} data
 * @param {object} config
 */
export const carouselTemplate = (data: EventsDataType, config: EventsConfigType): string => {
  if (data.items.length >= 3) {
    return `
      <div class="c-filterable-list__events c-filterable-list__events--carousel js-cmp-slider--events-carousel u-full-width" ref="carousel">
        <header class="events-carousel__header u-container-width">
          ${
            config.title
              ? `<h2 id="${config.titleId}" data-anchor-label="${config.anchorLabel || ''}" class="cmp-title__text events-carousel__header-title">${
                  config.title
                }</h2>`
              : ''
          }
          ${
            config.eventsPageLink
              ? `<a class="cmp-button cmp-filterable-list__allevents-link" href="${config.eventsPageLink}" data-dm="events.link">
            <span class="cmp-button__text">${config.translations.allEvents}</span>
            <span class="cmp-button__icon cmp-button__icon--keyboard_arrow_right icon--sm"></span>
          </a>`
              : '<span  class="cmp-filterable-list__allevents-link"></span>'
          }
        </header>
        <div class="splide">
          <div class="splide__track">
            <ul class="splide__list" ref="list" role="tablist">
              ${document.querySelector('.aem-AuthorLayer-Edit') ? '' : carouselItemsTemplate(data, config)}
            </ul>
          </div>
          <div class="splide__pagination-placeholder"
              role="tablist"
              aria-label="${config.translations.carouselChoseSlide}">
            </div>
          <div class="splide__arrows">
            <button class="splide__arrow splide__arrow--prev""
              type="button"
              aria-label="${config.translations.carouselPrevious}"
              data-dm="carousel.nav-next"
              data-cmp-hook-carousel="prev">
            </button>
            <button class="splide__arrow splide__arrow--next"
              type="button"
              aria-label="${config.translations.carouselNext}"
              data-dm="carousel.nav-next"
              data-cmp-hook-carousel="next">
            </button>
          </div>
        </div>
      </div>
    `
  } else return ''
}

/**
 * converts url params to an object
 * - gets the url params from browser url
 */
export const urlToObject = () => {
  const obj = Object.fromEntries(new URLSearchParams(location.search))
  Object.keys(obj).forEach(key => {
    obj[key] = obj[key].split(',')
  })
  return obj
}

/**
 * converts an object to url string
 * @param {object} o
 */
const objToUrlString = o => new URLSearchParams(o).toString()

/**
 * sets the url params in the browser url
 * @param {string} s - url params as string
 */
const updateUrl = s => window.history.replaceState({}, '', `${location.pathname}?${s}${location.hash}`)

/**
 * prefix object keys
 * @param {Object} obj
 */
const prefixObjectKeys = (obj, prefix) =>
  Object.assign(
    {},
    ...Object.keys(obj).map(key => {
      const item = {}
      item[prefix + key] = obj[key]
      return item
    }),
  )

/**
 * prevents unnecessary params to be rendered in the url
 * @param {Object} obj
 * @param {string} ignoreKey - key to be ignored in the url
 */
const filterUnnecessaryParamsInUrl = (obj, ignoreKey) =>
  Object.entries(obj)
    .filter(([k, _v]) => k !== ignoreKey)
    .reduce((acc, [k, v]) => {
      acc[k] = v
      return acc
    }, {})

/**
 * handles url changes
 */
export const UrlHandler = ({
  prefix = 'filter', // distinguish potentiel other params (e.g. 'wcmmode') from filter params
} = {}) => {
  /**
   * filter - returns entries that has the filter prefix
   * @param {array} [key, value] entry
   */
  const isPrefixedParam = ([key]) => key.includes(prefix)

  /**
   * filter/get other url params - not filter params
   * @param {Array} [key, value] entry
   */
  const isNotPrefixedParam = ([key]) => !key.includes(prefix)

  /**
   * removes the filter prefix from key
   * @param {array} entry - [key, value]
   */
  const stripPrefix = ([key, value]) => [key.replace(prefix, ''), value]

  /**
   * gets current url params that are not filter params
   */
  const getCurParamsNotPrefixed = () => Object.fromEntries(Object.entries(urlToObject()).filter(isNotPrefixedParam))

  /**
   * updates the url with params from object
   * @param {object} params
   * { param1: ['val1', 'val2'], param2: ['val']}
   */
  const update = params => {
    const filteredParams = filterUnnecessaryParamsInUrl(params, 'pastEventPresent')
    updateUrl(objToUrlString(Object.assign({}, getCurParamsNotPrefixed(), prefixObjectKeys(filteredParams, prefix))))
  }

  /**
   * gets the currently set (prefixed) params from url
   */
  const getCurrentParams = () => Object.fromEntries(Object.entries(urlToObject()).filter(isPrefixedParam).map(stripPrefix))

  return {
    set: update,
    get: getCurrentParams,
  }
}
